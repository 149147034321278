import React, {useState} from 'react';
import {useScrollSection, Section} from 'react-scroll-section';
import FooterContent from "../Footer/FooterContent";
import {Link} from 'react-scroll';
import {motion} from 'framer-motion';


function Menu(props) {

    let [menu, setMenu] = useState(false);
    let [selectedSection, setSelectedSection] = useState(0);

    const principleSection = useScrollSection('principles');
    const storySection = useScrollSection('story');
    const peopleSection = useScrollSection('people');
    const becomeElevenSection = useScrollSection('become');

    let bodyScroll = false;

    const toggleMenu = () => {
            setMenu(true);
    };

    const closeMenu = () => {
        setMenu(!menu);
    }


    const menuAnim = {
        hidden: {
        },
        show: {
            transition: {

                delayChildren: .4,
                staggerChildren: 0.1,
            }
        }
    }

    const itemAnim = {
        hidden: {
            opacity: 0,
            y: 100
        },
        show: {
            transition: 'tween',
            opacity: 1,
            y: 0,
        }
    }

    const staggerContainer = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            duration: 3,
            transition: {
                staggerChildren: .1,
                delayChildren: 2

            }
        }
    }


  return (
          <div className="menu__outer">
              <div className={`menu-btn ${(props.currentSectionIndex === 1 || props.currentSectionIndex === 3 ) ? 'active' : '' } ${( props.currentSectionIndex === 10 || props.currentSectionIndex === 9 || props.currentSectionIndex === 3 || props.currentSectionIndex === 4 || props.currentSectionIndex === 11  ) ? 'black' : '' } `} onClick={toggleMenu }>
                  menu
              </div>

              <div className={`menu-btn-mobile ${(props.currentSectionIndex === 1 || props.currentSectionIndex === 3 || props.currentSectionIndex === 4 || props.currentSectionIndex === 5 || props.currentSectionIndex === 6 || props.currentSectionIndex === 9 || props.currentSectionIndex === 10    ) ? "black" : ""}`} onClick={toggleMenu }>
                  menu
              </div>


              <motion.div variants={function() {
                      if (window.window.innerWidth <= 1100) {
                          return {
                              initial: { y: menu ? "-100%" : 0, x: 0 },
                              animate: { y: menu ? 0 : "-100%", x: 0 },
                          };
                      } else {
                        return {
                            initial: { x: menu ? "-100%" : 0, y: 0 },
                            animate: { x: menu ? 0 : "-100%", y: 0 },
                        };
                      }
                  }()} animate="animate" transition={{type: 'tween', duration: .6}} initial="initial" className={"menu"}>

                      {menu ? (
                          <div className="menu__side-click" onClick={closeMenu}></div>
                      ) : ''}


                      <div className="menu__heading">
                          <svg onClick={closeMenu} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.1 142.6">
                              <path d="M218.2 138.6h-42c-1.1 0-2-.9-2-2V5.9c0-1.1.9-2 2-2h42c1.1 0 2 .9 2 2v130.7c0 1.1-.9 2-2 2zm-40-4h38V7.9h-38v126.7z" fill="#fff"/>
                              <path  d="M151.1 138.6h-48.5c-.6 0-1.2-.3-1.6-.8l-15.7-21.2 2.5-3.3 15.8 21.3h43.5l-31.2-42.1c-3.2-4.2-8-6.7-13.3-6.7-5.3 0-10.1 2.4-13.3 6.7l-33.6 45.3c-.4.5-1 .8-1.6.8H5.5c-.8 0-1.4-.4-1.8-1.1-.3-.7-.3-1.5.2-2.1l47.6-64.2L3.9 7.1c-.4-.6-.5-1.4-.1-2.1s1-1.1 1.8-1.1h48.5c.6 0 1.2.3 1.6.8L89.3 50c3.2 4.2 8 6.7 13.3 6.7 5.3 0 10.1-2.4 13.3-6.7l31.2-42.1h-43.5L87.8 29.2l-2.5-3.3L101 4.7c.4-.5 1-.8 1.6-.8h48.5c.8 0 1.4.4 1.8 1.1.3.7.3 1.5-.2 2.1l-33.6 45.3c-3.9 5.3-9.9 8.3-16.5 8.3s-12.6-3-16.5-8.3l-33-44.5H9.5l46.1 62.2c.5.7.5 1.7 0 2.4L9.5 134.6H53l33-44.5c3.9-5.3 9.9-8.3 16.5-8.3s12.6 3 16.5 8.3l33.6 45.3c.5.6.5 1.4.2 2.1-.3.7-1 1.1-1.7 1.1z" fill="#db171e"/>
                          </svg>
                          <span>The elevens</span>
                          <div className={`line ${menu ? "show" : '' }`}  >
                          </div>

                      </div>
                      <div className="menu__nav">
                          <motion.ul variants={menuAnim} initial="hidden" animate={menu ? "show" : ''}>
                              <motion.li variants={itemAnim} onClick={() => { props.setActiveFunction(0); closeMenu() }}>
                                      Our story
                              </motion.li>
                              <motion.li variants={itemAnim} onClick={() => { props.setActiveFunction(7); closeMenu() }}>
                                    Our principles
                              </motion.li>
                              <motion.li variants={itemAnim} onClick={() => { props.setActiveFunction(8); closeMenu() }}>
                                      Our people
                              </motion.li>
                              <motion.li onClick={() => { props.setActiveFunction(10); closeMenu() }} variants={itemAnim} >
                                      Become an eleven
                              </motion.li>
                          </motion.ul>
                          <h2 className={`heading-l ${menu ? "show" : "" }`} >
                              <div>
                                  <span>investing</span>
                              </div>
                              <div>
                                  <span>in</span>
                              </div>
                              <div>
                                  <span>people.</span>
                              </div>
                          </h2>
                          <FooterContent greyIcon={false} logo={false} />
                      </div>
                      <div className="btn-close" onClick={closeMenu}>
                          close

                      </div>

                      <div className="btn-close-mobile" onClick={closeMenu}>

                      </div>
                  </motion.div>
          </div>
  )
}

export default Menu