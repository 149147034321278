 import React, {useState, useEffect, useRef} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import './assets/scss/main.scss';

import man from "./assets/images/man.png";
import woman from "./assets/images/woman.png"
import animations from "./helpers/animations";
import People from "./Components/People/People";
import Principles from "./Components/Principles/Principles";
import SectionOneCurves from "./Components/Curves/SectionOneCurves";
import SectionTwoCurves from "./Components/Curves/SectionTwoCurves";
import SectionThreeCurves from "./Components/Curves/SectionThreeCurves";
import SectionSixCurves from "./Components/Curves/SectionSixCurves";
import SectionSevenCurves from "./Components/Curves/SectionSevenCurves";
import FooterContent from './Components/Footer/FooterContent';




import useOnScreen from "./helpers/useOnScreen";

function Sections(props) {


    useEffect(() => {
        document.title = "The elevens"
    }, []);

    let [background, removeBackground] = useState(false);



  return (
                <div className={`elevens__inner`}>

                    <div id={`story`} className={`full-height section-1 ${props.activeSection === 0 ? 'active-section' : '' }`} >
                                    <SectionOneCurves trigger={props.activeSection === 0 } />
                                    <AnimatePresence>
                                        {props.activeSection === 0 ? (
                                            <motion.h1 variants={animations.sectionOneText} initial={'initial'} exit={'animateOut'}  animate={ props.activeSection === 0 ? "animate" : '' } transition={{duration: 1, type: 'tween', ease: 'easeIn'}}>
                                                <div>
                                                    <span >Take</span>
                                                </div>
                                                <div>
                                                    <span >a</span>
                                                </div>
                                                <div>
                                                    <span >deep</span>
                                                </div>
                                                <div>
                                                    <span>breath</span>
                                                </div>
                                            </motion.h1>
                                        ) : '' }

                                    </AnimatePresence>
                            </div>


                        <div className={`full-height section-2 ${props.activeSection === 1 ? 'active-section' : '' }`}>
                            <SectionTwoCurves trigger={props.activeSection === 1}/>
                            <AnimatePresence>
                                {props.activeSection === 1 ? (
                                    <motion.h2 variants={animations.sectionTwoText} initial={'initial'} animate={props.activeSection === 1 ? 'animate' : ''} exit={'animateOut'} transition={{delay: .7 , duration: 1, type: 'tween' }}>
                                        <div  >And</div>
                                        <div>count</div>
                                        <div className={'counter'} >
                                            to ten.
                                        </div>

                                    </motion.h2>
                                ) : ''}
                            </AnimatePresence>
                        </div>

                        <div className={`full-height section-3 ${props.activeSection === 2 ? 'active-section' : '' }`}>
                           <SectionThreeCurves trigger={props.activeSection === 2} />
                           <AnimatePresence>
                               {props.activeSection === 2 ? (
                                   <motion.h2 variants={animations.sectionThreeText} initial={"initial"}  animate={ props.activeSection === 2 ? "animate" : '' } exit={'exit'} transition={{delay: .7 , duration: 1, type: 'tween'}}>
                                       <div>
                                           <span>What's</span>

                                       </div>
                                       <div>
                                           <span>next?</span>
                                       </div>
                                   </motion.h2>
                               ) : ""}

                           </AnimatePresence>

                        </div>


                    <div className={`full-height section-4 ${props.activeSection === 3 ? 'active-section-overflow' : '' }`}>
                                    <div className="section-4__inner" >
                                            {function renderSectionFourText() {
                                                if (props.prevActiveSection === 2 && props.activeSection === 3) {
                                                    return  <motion.svg variants={animations.sectionFourTextA} initial={"initial"} animate={"animate"} transition={{duration: 1, type:'tween'}} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 217.86 175.05">
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M215.67,134.7h-42a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h42a2,2,0,0,1,2,2V132.7A2,2,0,0,1,215.67,134.7Zm-40-4h38V4h-38Z"/>
                                                        <path fill={'#db121c'} className="cls-2"
                                                              d="M148.53,134.7H100a2,2,0,0,1-1.6-.8L82.7,112.74l2.52-3.32L101,130.7h43.53L113.31,88.61a16.57,16.57,0,0,0-26.59,0L53.11,133.9a2,2,0,0,1-1.61.8H3a2,2,0,0,1-1.6-3.19L49,67.35,1.39,3.19A2,2,0,0,1,3,0H51.5a2,2,0,0,1,1.61.81L86.72,46.09a16.56,16.56,0,0,0,26.59,0L144.55,4H101L85.22,25.28l-2.5-3.34L98.41.81A2,2,0,0,1,100,0h48.52a2,2,0,0,1,1.6,3.19L116.52,48.47a20.55,20.55,0,0,1-33,0L50.5,4H7L53.11,66.16a2,2,0,0,1,0,2.38L7,130.7H50.5l33-44.47a20.56,20.56,0,0,1,33,0l33.61,45.28a2,2,0,0,1-1.6,3.19Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M0,156.89H9.79v3.33H6.56V174.6H3.14V160.22H0Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M19.43,156.89h3.42v6.68h5.91v-6.68h3.4V174.6h-3.4v-7.76H22.85v7.76H19.43Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M43.72,156.89h9.67v3.3H47.07v3.2h6.32v3.24H47.07v4.66h6.32v3.31H43.72Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M78.24,156.89h9.67v3.3H81.58v3.2h6.33v3.24H81.58v4.66h6.33v3.31H78.24Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M98.92,156.89h3.37v14.5h4.92v3.21H98.92Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M117,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31H117Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M136,156.89h3.47L144,169.47l4.52-12.58h3.46l-6.38,17.71H142.3Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M161.77,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31h-9.67Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M182.47,156.89h3.23l7.58,11.66V156.89h3.37V174.6h-3.24L185.84,163V174.6h-3.37Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M217.54,159.3l-2.49,2.2c-.88-1.22-1.77-1.83-2.68-1.83a1.63,1.63,0,0,0-1.09.36,1,1,0,0,0-.42.8,1.35,1.35,0,0,0,.3.84,23.79,23.79,0,0,0,2.47,2.28c1.29,1.07,2.07,1.75,2.34,2a7,7,0,0,1,1.46,2,5,5,0,0,1,.43,2.07,4.76,4.76,0,0,1-1.51,3.6,5.53,5.53,0,0,1-3.93,1.42,5.86,5.86,0,0,1-3.3-.93,7.42,7.42,0,0,1-2.4-2.91l2.83-1.71c.85,1.56,1.84,2.35,2.94,2.35a2.15,2.15,0,0,0,1.46-.51,1.5,1.5,0,0,0,.59-1.17,2,2,0,0,0-.44-1.2,12.49,12.49,0,0,0-2-1.84,20.1,20.1,0,0,1-3.74-3.65,4.61,4.61,0,0,1-.84-2.55,4.18,4.18,0,0,1,1.4-3.16,4.88,4.88,0,0,1,3.47-1.32,5.45,5.45,0,0,1,2.52.61A9.24,9.24,0,0,1,217.54,159.3Z"/>
                                                    </motion.svg>
                                                }
                                                if (props.prevActiveSection === 3 && props.activeSection === 2) {
                                                    return  <motion.svg variants={animations.sectionFourTextB} initial={"initial"} animate={"animate"} transition={{duration: 1, type:'tween'}} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 217.86 175.05">
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M215.67,134.7h-42a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h42a2,2,0,0,1,2,2V132.7A2,2,0,0,1,215.67,134.7Zm-40-4h38V4h-38Z"/>
                                                        <path fill={'#db121c'} className="cls-2"
                                                              d="M148.53,134.7H100a2,2,0,0,1-1.6-.8L82.7,112.74l2.52-3.32L101,130.7h43.53L113.31,88.61a16.57,16.57,0,0,0-26.59,0L53.11,133.9a2,2,0,0,1-1.61.8H3a2,2,0,0,1-1.6-3.19L49,67.35,1.39,3.19A2,2,0,0,1,3,0H51.5a2,2,0,0,1,1.61.81L86.72,46.09a16.56,16.56,0,0,0,26.59,0L144.55,4H101L85.22,25.28l-2.5-3.34L98.41.81A2,2,0,0,1,100,0h48.52a2,2,0,0,1,1.6,3.19L116.52,48.47a20.55,20.55,0,0,1-33,0L50.5,4H7L53.11,66.16a2,2,0,0,1,0,2.38L7,130.7H50.5l33-44.47a20.56,20.56,0,0,1,33,0l33.61,45.28a2,2,0,0,1-1.6,3.19Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M0,156.89H9.79v3.33H6.56V174.6H3.14V160.22H0Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M19.43,156.89h3.42v6.68h5.91v-6.68h3.4V174.6h-3.4v-7.76H22.85v7.76H19.43Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M43.72,156.89h9.67v3.3H47.07v3.2h6.32v3.24H47.07v4.66h6.32v3.31H43.72Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M78.24,156.89h9.67v3.3H81.58v3.2h6.33v3.24H81.58v4.66h6.33v3.31H78.24Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M98.92,156.89h3.37v14.5h4.92v3.21H98.92Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M117,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31H117Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M136,156.89h3.47L144,169.47l4.52-12.58h3.46l-6.38,17.71H142.3Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M161.77,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31h-9.67Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M182.47,156.89h3.23l7.58,11.66V156.89h3.37V174.6h-3.24L185.84,163V174.6h-3.37Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M217.54,159.3l-2.49,2.2c-.88-1.22-1.77-1.83-2.68-1.83a1.63,1.63,0,0,0-1.09.36,1,1,0,0,0-.42.8,1.35,1.35,0,0,0,.3.84,23.79,23.79,0,0,0,2.47,2.28c1.29,1.07,2.07,1.75,2.34,2a7,7,0,0,1,1.46,2,5,5,0,0,1,.43,2.07,4.76,4.76,0,0,1-1.51,3.6,5.53,5.53,0,0,1-3.93,1.42,5.86,5.86,0,0,1-3.3-.93,7.42,7.42,0,0,1-2.4-2.91l2.83-1.71c.85,1.56,1.84,2.35,2.94,2.35a2.15,2.15,0,0,0,1.46-.51,1.5,1.5,0,0,0,.59-1.17,2,2,0,0,0-.44-1.2,12.49,12.49,0,0,0-2-1.84,20.1,20.1,0,0,1-3.74-3.65,4.61,4.61,0,0,1-.84-2.55,4.18,4.18,0,0,1,1.4-3.16,4.88,4.88,0,0,1,3.47-1.32,5.45,5.45,0,0,1,2.52.61A9.24,9.24,0,0,1,217.54,159.3Z"/>
                                                    </motion.svg>
                                                }
                                                if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                                    return  <motion.svg variants={animations.sectionFourTextC} initial={"initial"} animate={"animate"} transition={{duration: 1, type:'tween'}} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 217.86 175.05">
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M215.67,134.7h-42a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h42a2,2,0,0,1,2,2V132.7A2,2,0,0,1,215.67,134.7Zm-40-4h38V4h-38Z"/>
                                                        <path fill={'#db121c'} className="cls-2"
                                                              d="M148.53,134.7H100a2,2,0,0,1-1.6-.8L82.7,112.74l2.52-3.32L101,130.7h43.53L113.31,88.61a16.57,16.57,0,0,0-26.59,0L53.11,133.9a2,2,0,0,1-1.61.8H3a2,2,0,0,1-1.6-3.19L49,67.35,1.39,3.19A2,2,0,0,1,3,0H51.5a2,2,0,0,1,1.61.81L86.72,46.09a16.56,16.56,0,0,0,26.59,0L144.55,4H101L85.22,25.28l-2.5-3.34L98.41.81A2,2,0,0,1,100,0h48.52a2,2,0,0,1,1.6,3.19L116.52,48.47a20.55,20.55,0,0,1-33,0L50.5,4H7L53.11,66.16a2,2,0,0,1,0,2.38L7,130.7H50.5l33-44.47a20.56,20.56,0,0,1,33,0l33.61,45.28a2,2,0,0,1-1.6,3.19Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M0,156.89H9.79v3.33H6.56V174.6H3.14V160.22H0Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M19.43,156.89h3.42v6.68h5.91v-6.68h3.4V174.6h-3.4v-7.76H22.85v7.76H19.43Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M43.72,156.89h9.67v3.3H47.07v3.2h6.32v3.24H47.07v4.66h6.32v3.31H43.72Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M78.24,156.89h9.67v3.3H81.58v3.2h6.33v3.24H81.58v4.66h6.33v3.31H78.24Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M98.92,156.89h3.37v14.5h4.92v3.21H98.92Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M117,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31H117Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M136,156.89h3.47L144,169.47l4.52-12.58h3.46l-6.38,17.71H142.3Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M161.77,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31h-9.67Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M182.47,156.89h3.23l7.58,11.66V156.89h3.37V174.6h-3.24L185.84,163V174.6h-3.37Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M217.54,159.3l-2.49,2.2c-.88-1.22-1.77-1.83-2.68-1.83a1.63,1.63,0,0,0-1.09.36,1,1,0,0,0-.42.8,1.35,1.35,0,0,0,.3.84,23.79,23.79,0,0,0,2.47,2.28c1.29,1.07,2.07,1.75,2.34,2a7,7,0,0,1,1.46,2,5,5,0,0,1,.43,2.07,4.76,4.76,0,0,1-1.51,3.6,5.53,5.53,0,0,1-3.93,1.42,5.86,5.86,0,0,1-3.3-.93,7.42,7.42,0,0,1-2.4-2.91l2.83-1.71c.85,1.56,1.84,2.35,2.94,2.35a2.15,2.15,0,0,0,1.46-.51,1.5,1.5,0,0,0,.59-1.17,2,2,0,0,0-.44-1.2,12.49,12.49,0,0,0-2-1.84,20.1,20.1,0,0,1-3.74-3.65,4.61,4.61,0,0,1-.84-2.55,4.18,4.18,0,0,1,1.4-3.16,4.88,4.88,0,0,1,3.47-1.32,5.45,5.45,0,0,1,2.52.61A9.24,9.24,0,0,1,217.54,159.3Z"/>
                                                    </motion.svg>
                                                }
                                                if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                                    return  <motion.svg variants={animations.sectionFourTextD} initial={"initial"} animate={"animate"}transition={{duration: 1, type:'tween'}} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 217.86 175.05">
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M215.67,134.7h-42a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h42a2,2,0,0,1,2,2V132.7A2,2,0,0,1,215.67,134.7Zm-40-4h38V4h-38Z"/>
                                                        <path fill={'#db121c'} className="cls-2"
                                                              d="M148.53,134.7H100a2,2,0,0,1-1.6-.8L82.7,112.74l2.52-3.32L101,130.7h43.53L113.31,88.61a16.57,16.57,0,0,0-26.59,0L53.11,133.9a2,2,0,0,1-1.61.8H3a2,2,0,0,1-1.6-3.19L49,67.35,1.39,3.19A2,2,0,0,1,3,0H51.5a2,2,0,0,1,1.61.81L86.72,46.09a16.56,16.56,0,0,0,26.59,0L144.55,4H101L85.22,25.28l-2.5-3.34L98.41.81A2,2,0,0,1,100,0h48.52a2,2,0,0,1,1.6,3.19L116.52,48.47a20.55,20.55,0,0,1-33,0L50.5,4H7L53.11,66.16a2,2,0,0,1,0,2.38L7,130.7H50.5l33-44.47a20.56,20.56,0,0,1,33,0l33.61,45.28a2,2,0,0,1-1.6,3.19Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M0,156.89H9.79v3.33H6.56V174.6H3.14V160.22H0Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M19.43,156.89h3.42v6.68h5.91v-6.68h3.4V174.6h-3.4v-7.76H22.85v7.76H19.43Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M43.72,156.89h9.67v3.3H47.07v3.2h6.32v3.24H47.07v4.66h6.32v3.31H43.72Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M78.24,156.89h9.67v3.3H81.58v3.2h6.33v3.24H81.58v4.66h6.33v3.31H78.24Z"/>
                                                        <path fill={'#1a282d'} className="cls-1" d="M98.92,156.89h3.37v14.5h4.92v3.21H98.92Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M117,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31H117Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M136,156.89h3.47L144,169.47l4.52-12.58h3.46l-6.38,17.71H142.3Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M161.77,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31h-9.67Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M182.47,156.89h3.23l7.58,11.66V156.89h3.37V174.6h-3.24L185.84,163V174.6h-3.37Z"/>
                                                        <path fill={'#1a282d'} className="cls-1"
                                                              d="M217.54,159.3l-2.49,2.2c-.88-1.22-1.77-1.83-2.68-1.83a1.63,1.63,0,0,0-1.09.36,1,1,0,0,0-.42.8,1.35,1.35,0,0,0,.3.84,23.79,23.79,0,0,0,2.47,2.28c1.29,1.07,2.07,1.75,2.34,2a7,7,0,0,1,1.46,2,5,5,0,0,1,.43,2.07,4.76,4.76,0,0,1-1.51,3.6,5.53,5.53,0,0,1-3.93,1.42,5.86,5.86,0,0,1-3.3-.93,7.42,7.42,0,0,1-2.4-2.91l2.83-1.71c.85,1.56,1.84,2.35,2.94,2.35a2.15,2.15,0,0,0,1.46-.51,1.5,1.5,0,0,0,.59-1.17,2,2,0,0,0-.44-1.2,12.49,12.49,0,0,0-2-1.84,20.1,20.1,0,0,1-3.74-3.65,4.61,4.61,0,0,1-.84-2.55,4.18,4.18,0,0,1,1.4-3.16,4.88,4.88,0,0,1,3.47-1.32,5.45,5.45,0,0,1,2.52.61A9.24,9.24,0,0,1,217.54,159.3Z"/>
                                                    </motion.svg>
                                                }
                                                return null;
                                            }()}

                                            {function renderSectionFourText() {
                                                if (props.prevActiveSection === 2 && props.activeSection === 3) {
                                                    return <motion.h3 variants={animations.sectionFourTextA} initial={"initial"} animate={"animate"}  transition={{duration: 1, type:'tween'}}><div><span>We are The Elevens.</span> </div><div><span> A group of individuals.</span> </div><div><span>United as one.</span></div></motion.h3>
                                                }
                                                if (props.prevActiveSection === 3 && props.activeSection === 2) {
                                                    return <motion.h3 variants={animations.sectionFourTextB} initial={"initial"} animate={"animate"}  transition={{duration: 1, type:'tween'}}><div><span>We are The Elevens.</span> </div><div><span> A group of individuals.</span> </div><div><span>United as one.</span></div></motion.h3>
                                                }
                                                if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                                    return <motion.h3 variants={animations.sectionFourTextC} initial={"initial"} animate={"animate"} transition={{duration: 1, type:'tween'}}><div><span>We are The Elevens.</span> </div><div><span> A group of individuals.</span> </div><div><span>United as one.</span></div></motion.h3>
                                                }
                                                if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                                    return <motion.h3 variants={animations.sectionFourTextD} initial={"initial"} animate={"animate"} transition={{duration: 1, type:'tween'}}><div><span>We are The Elevens.</span> </div><div><span> A group of individuals.</span> </div><div><span>United as one.</span></div></motion.h3>
                                                }
                                                return null;
                                            }()}
                                    </div>
                    </div>




                        <div  className={`full-height section-5 ${props.activeSection === 4 ? 'active-section' : '' }`}>
                            <div className="curves" >
                                    {function renderSectionFiveArrow() {
                                        if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                            return <motion.svg variants={animations.sectionFiveArrowA} initial={'initial'} animate={'animate'} transition={{delay: .1,duration: 1, type: 'tween', ease: 'easeInOut'}}  className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.68 541.47"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9,213.6,276.85a5.36,5.36,0,0,1,.05,6.38L23.1,532.35"/></g></g></motion.svg>
                                        }
                                        if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                            return <motion.svg variants={animations.sectionFiveArrowB} initial={'initial'} animate={'animate'} transition={{delay: 0,duration: 1 , type: 'tween' , ease: 'easeInOut'}}  className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.68 541.47"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9,213.6,276.85a5.36,5.36,0,0,1,.05,6.38L23.1,532.35"/></g></g></motion.svg>
                                        }
                                        if (props.prevActiveSection === 4 && props.activeSection === 5) {
                                            return <motion.svg variants={animations.sectionFiveArrowC} initial={'initial'} animate={'animate'} transition={{delay: .4,duration: 1 , type: 'tween' , ease: 'easeInOut'}}  className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.68 541.47"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9,213.6,276.85a5.36,5.36,0,0,1,.05,6.38L23.1,532.35"/></g></g></motion.svg>
                                        }
                                        if (props.prevActiveSection === 5 && props.activeSection === 4) {
                                            return <motion.svg variants={animations.sectionFiveArrowD} initial={'initial'} animate={'animate'} transition={{delay: .4,duration: 1 , type: 'tween' , ease: 'easeInOut'}}  className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.68 541.47"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9,213.6,276.85a5.36,5.36,0,0,1,.05,6.38L23.1,532.35"/></g></g></motion.svg>
                                        }
                                        return null;
                                    }()}
                            </div>

                            {/*<div className="man">*/}
                            {/*    <motion.img variants={animations.peopleBackAnim} initial={{x: "100%"}} animate={ {x: props.activeSection === 4 ? 0 : ""}} transition={{delay: .7, duration: .6}} src={man} alt=""/>*/}
                            {/*    <motion.img src={man} variants={animations.peopleBackAnim} initial={{x: "1035px"}} animate={ {x: props.activeSection === 4 ? 0 : ""}} transition={{delay: .8, duration: .6}} alt=""/>*/}
                            {/*    <motion.img variants={animations.peopleBackAnim} initial={{x: "1340px"}} animate={ {x: props.activeSection === 4 ? 0 : ""}} transition={{delay: .9, duration: .6}} src={man} alt=""/>*/}
                            {/*    <motion.img variants={animations.peopleBackAnim} initial={{x: "1530px"}} animate={ {x: props.activeSection === 4 ? 0 : ""}} transition={{delay: 1, duration: .6}} src={man} alt=""/>*/}

                            {/*</div>*/}
                                {function renderSectionFiveText() {
                                    if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                        return <motion.h3 variants={animations.sectionFiveTextA} initial={"initial"} animate={ "animate"} transition={{delay: .1, duration: 1, type: 'tween' , ease: 'easeInOut'}}><div>Pushing each other forward</div><div> as one team striving for success.</div></motion.h3>
                                    }
                                    if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                        return <motion.h3 variants={animations.sectionFiveTextB} initial={"initial"} animate={"animate" } transition={{delay: 0, duration: 1, type: 'tween' , ease: 'easeInOut'}}><div>Pushing each other forward</div><div> as one team striving for success.</div></motion.h3>
                                    }
                                    if (props.prevActiveSection === 4 && props.activeSection === 5) {
                                        return <motion.h3 variants={animations.sectionFiveTextC} initial={"initial"} animate={ "animate"} transition={{delay: .4, duration: 1, type: 'tween' , ease: 'easeInOut'}}><div>Pushing each other forward</div><div> as one team striving for success.</div></motion.h3>
                                    }
                                    if (props.prevActiveSection === 5 && props.activeSection === 4) {
                                        return <motion.h3 variants={animations.sectionFiveTextD} initial={"initial"} animate={ "animate"} transition={{delay: .4, duration: 1, type: 'tween' , ease: 'easeInOut'}}><div>Pushing each other forward</div><div> as one team striving for success.</div></motion.h3>
                                    }
                                    return null;
                                }()}
                        </div>


                        <div  className={`full-height section-6 ${props.activeSection === 5 ? 'active-section' : '' }`}>
                            <SectionSixCurves activeSection={props.activeSection} prevActiveSection={props.prevActiveSection} trigger={props.activeSection === 5} />
                                {function renderSectionSixText() {
                                    if (props.prevActiveSection === 4 && props.activeSection === 5) {
                                        return <motion.h3 variants={animations.sectionSixTextA} initial={"initial"} animate={"animate"}  transition={{delay: .4, duration: 1, type: 'tween', ease: 'easeInOut'}}>That's what we do.</motion.h3>
                                    }
                                    if (props.prevActiveSection === 5 && props.activeSection === 4) {
                                        return <motion.h3 variants={animations.sectionSixTextB} initial={"initial"} animate={"animate"}  transition={{delay: .4, duration: 1 , type: 'tween', ease: 'easeInOut'}}>That's what we do.</motion.h3>
                                    }
                                    if (props.prevActiveSection === 5 && props.activeSection === 6) {
                                        return <motion.h3 variants={animations.sectionSixTextC} initial={"initial"} animate={"animate"}  transition={{ delay: 0, duration: .3 , type: 'tween', ease: 'easeInOut'}}>That's what we do.</motion.h3>
                                    }
                                    if (props.prevActiveSection === 6 && props.activeSection === 5) {
                                        return <motion.h3 variants={animations.sectionSixTextD} initial={"initial"} animate={"animate"}  transition={{ delay: .4, duration: .5 , type: 'tween', ease: 'easeInOut'}}>That's what we do.</motion.h3>
                                    }
                                    return null;
                                }()}
                        </div>


                        <div className={`full-height section-7 ${props.activeSection === 6 ? 'active-section' : '' }`}>
                                {function renderSectionSevenText() {
                                    if (props.prevActiveSection === 5 && props.activeSection === 6) {
                                        return <motion.h3 variants={animations.sectionSevenTextA} initial={"initial"} animate={"animate"} exit={'exit'} transition={{delay: .4, duration: .3}}>
                                            <div>And we love it.</div>
                                        </motion.h3>
                                    }
                                    if (props.prevActiveSection === 6 && props.activeSection === 5) {
                                        return <motion.h3 variants={animations.sectionSevenTextB} initial={"initial"} animate={"animate"} exit={'exit'} transition={{delay: 0, duration: .5}}><div>And we love it.</div></motion.h3>
                                    }
                                    if (props.prevActiveSection === 6 && props.activeSection === 7) {
                                        return <motion.h3 variants={animations.sectionSevenTextC} initial={"initial"} animate={"animate"} exit={'exit'} transition={{delay: .5, duration: .5}}><div>And we love it.</div></motion.h3>
                                    }
                                    if (props.prevActiveSection === 7 && props.activeSection === 6) {
                                        return <motion.h3 variants={animations.sectionSevenTextD} initial={"initial"} animate={"animate"} exit={'exit'} transition={{delay: .5, duration: .5}}><div>And we love it.</div></motion.h3>
                                    }
                                    return null;
                                }()}
                        </div>


                        {props.activeSection >= 6 && props.activeSection <= 8 ? (
                            <Principles activeSection={props.activeSection} prevActiveSection={props.prevActiveSection} trigger={props.activeSection === 7} />
                        ) : null}

                        {props.activeSection >= 7 && props.activeSection <= 9 ? <People activeSection={props.activeSection} prevActiveSection={props.prevActiveSection} trigger={props.activeSection === 8 || props.activeSection === 9} /> : null}

                        <div className={`section-12 bottom-footer ${props.activeSection === 10 || props.activeSection === 11 ? 'active-section' : '' }`}>
                            <FooterContent greyIcon={true} goHome={props.goHome} logo={true} />
                        </div>

                        {props.activeSection === 8 || props.activeSection === 9 || props.activeSection === 10 || props.activeSection === 11 ? (
                            <motion.div transition={{ type: 'tween' }} variants={function() {
                                if (props.activeSection === 8) {
                                    return {
                                        initial: {
                                            y: "100%",
                                        },
                                        animate: {
                                            y: "100%",
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 8 && props.activeSection === 9) {
                                    return {
                                        initial: {
                                            y: "100%",
                                        },
                                        animate: {
                                            y: 0,
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 9 && props.activeSection === 8) {
                                    return {
                                        initial: {
                                            y: "100%",
                                        },
                                        animate: {
                                            y: 0,
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 10 && props.activeSection === 11) {
                                    return {
                                        initial: {
                                            y: 0,
                                        },
                                        animate: {
                                            y: "-55vh",
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 11 && props.activeSection === 10) {
                                    return {
                                        initial: {
                                            y: "-55vh",
                                        },
                                        animate: {
                                            y: 0,
                                        },
                                    };
                                }
                                return {
                                    initial: {
                                        y: 0,
                                    },
                                    animate: {
                                        y: 0,
                                    },
                                };
                            }()} initial={"initial"} animate={"animate"} transition={{duration: 1, type: 'tween'}} className={`full-height section-11 ${props.activeSection === 8 || props.activeSection === 9 || props.activeSection === 10 || props.activeSection === 11 ? 'active-section' : '' }`} id={'become'}>
                                <div className="man">
                                    <motion.img variants={animations.peopleBackAnimA} initial={{ x: "-780px" }} animate={ props.activeSection === 10 || props.activeSection === 9 || props.activeSection === 11 ? "animate" : '' } transition={{duration: .6, delay: .6}} src={woman} alt=""/>
                                    <motion.img src={man} variants={animations.peopleBackAnimA} initial={{ x: "-950px"}} animate={ props.activeSection === 10 || props.activeSection === 9  || props.activeSection === 11? "animate" : '' } transition={{delay: .7, duration: .6}} alt=""/>
                                </div>
                                <motion.div variants={function() {
                                    if (props.prevActiveSection === 8 && props.activeSection === 9) {
                                        return {
                                            initial: {
                                                opacity: 0,
                                                y: 900,
                                            },
                                            animate: {
                                                opacity: 1,
                                                y: 450,
                                            },
                                        };
                                    }
                                    if (props.prevActiveSection === 9 && props.activeSection === 10) {
                                        return {
                                            initial: {
                                                y: 450,
                                                opacity: 1,
                                            },
                                            animate: {
                                                y: 0,
                                                opacity: 1,
                                            },
                                        };
                                    }
                                    if (props.prevActiveSection === 10 && props.activeSection === 9) {
                                        return {
                                            initial: {
                                                y: 0,
                                                opacity: 1,
                                            },
                                            animate: {
                                                y: 450,
                                                opacity: 1,
                                            },
                                        };
                                    }
                                    if ((props.prevActiveSection === 10 && props.activeSection === 11) ) {
                                        return {
                                            initial: {
                                                y: 0,
                                                opacity: 1,
                                            },
                                            animate: {
                                                y: 0,
                                                opacity: 0,
                                            },
                                        };
                                    }
                                    if (props.prevActiveSection === 11 && props.activeSection === 10) {
                                        return {
                                            initial: {
                                                y: 0,
                                                opacity: 0,
                                            },
                                            animate: {
                                                y: 0,
                                                opacity: 1,
                                            },
                                        };
                                    }
                                    if (props.activeSection === 10) {
                                        return {
                                            initial: {
                                                y: 450,
                                                opacity: 1,
                                            },
                                            animate: {
                                                y: 0,
                                                opacity: 1,
                                            },
                                        };
                                    }
                                    return {
                                        initial: {
                                            y: 900,
                                            opacity: 0,
                                        },
                                        animate: {
                                            y: 450,
                                            opacity: 1,
                                        },
                                    };
                                }()} animate="animate" initial="initial" transition={{duration: 1, type: 'tween'}} className="section-11__inner">
                                    <div className="section-11__content">
                                        <h3 variants={animations.contentBlockItemAnimation}><span>Do you want to</span><span> become an Eleven?</span></h3>
                                        <h5 variants={animations.contentBlockItemAnimation}>We are always on the lookout for the next member of our team. Give us a call or send us an email!</h5>
                                        <div className="section-11__contact">
                                            <a href="tel:+31610403708">+31 (0) 6 104 037 08</a>
                                            <a href="mailto:info@elevens.nl">info@elevens.nl</a>
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        ) : null}

                        <motion.div variants={function() {
                            if (props.activeSection < 8) {
                                return {
                                    initial: {
                                        y: "100vh",
                                    },
                                    animate: {
                                        y: "100vh",
                                    },
                                };
                            }
                            if (props.activeSection === 8) {
                                return {
                                    initial: {
                                        y: "100vh",
                                    },
                                    animate: {
                                        y: "100vh",
                                    },
                                };
                            }
                            if (props.prevActiveSection === 8 && props.activeSection === 9) {
                                return {
                                    initial: {
                                        y: "100vh",
                                    },
                                    animate: {
                                        y: 0,
                                    },
                                };
                            }
                            if (props.prevActiveSection === 9 && props.activeSection === 8) {
                                return {
                                    initial: {
                                        y: 0,
                                    },
                                    animate: {
                                        y: "100vh",
                                    },
                                };
                            }
                            if (props.prevActiveSection === 9 && props.activeSection === 10) {
                                return {
                                    initial: {
                                        y: 0,
                                    },
                                    animate: {
                                        y: -450,
                                    },
                                };
                            }
                            if (props.prevActiveSection === 10 && props.activeSection === 9) {
                                return {
                                    initial: {
                                        y: -450,
                                    },
                                    animate: {
                                        y: 0,
                                    },
                                };
                            }
                            if ((props.prevActiveSection === 11 && props.activeSection === 10) || (props.prevActiveSection === 10 && props.activeSection === 11)) {
                                return {
                                    initial: {
                                        y: -450,
                                    },
                                    animate: {
                                        y: -450,
                                    },
                                };
                            }
                            if (props.activeSection === 10) {
                                return {
                                    initial: {
                                        y: 0
                                    },
                                    animate: {
                                        y: -450,
                                    },
                                };
                            }
                            return {
                                initial: {
                                    y: 0,
                                },
                                animate: {
                                    y: 0,
                                },
                            };
                        }()} initial={"initial"} animate={"animate"} transition={{duration: 1, type: 'tween'}} className={`section-10 ${props.activeSection === 8 || props.activeSection === 9 || props.activeSection === 10 || props.activeSection === 11 ? 'active-section' : '' }`} id={'become'}>
                            <motion.div variants={function() {
                                if (props.prevActiveSection === 8 && props.activeSection === 9) {
                                    return {
                                        initial: {
                                            opacity: 0,
                                            y: "100px",
                                            transition: {
                                                duration: 1
                                            }
                                        },
                                        animate: {
                                           opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 9 && props.activeSection === 8) {
                                    return {
                                        initial: {
                                            opacity: 0,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }

                                        },
                                        animate: {
                                            opacity: 0,
                                            y: "100px",
                                            transition: {
                                                duration: 1
                                            }
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 9 && props.activeSection === 10) {
                                    return {
                                        initial: {
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }

                                        },
                                        animate: {
                                            opacity: 0,
                                            y: "-100px",
                                            transition: {
                                                duration: 1
                                            }
                                        },
                                    };
                                }
                                if (props.prevActiveSection === 10 && props.activeSection === 9) {
                                    return {
                                        initial: {
                                            opacity: 0,
                                            y: "-100px",
                                            transition: {
                                                duration: 1
                                            }

                                        },
                                        animate: {
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        },
                                    };
                                }
                                return {
                                    initial: {
                                        opacity: 0,
                                        y: "100px",
                                        transition: {
                                            duration: 1
                                        }
                                    },
                                    animate: {
                                        opacity: 1,
                                        y: 0,
                                        transition: {
                                            duration: 1
                                        }
                                    }
                                }
                            }()} initial={"initial"} animate={"animate"} transition={{type: 'tween'}} className="section-10__inner" >
                                <h1>
                                    <span>1</span>
                                    <span variants={animations.topTextAnimation} className={'math'}>+</span>
                                    <span variants={animations.topTextAnimation}>1</span>
                                    <span variants={animations.topTextAnimation} className={'math'}>=</span>
                                    <span variants={animations.topTextAnimation}>11</span>
                                </h1>
                                <h4>
                                    <div>
                                        You can't beat our math.
                                    </div>
                                    <div>
                                        But with us, you can beat the odds.
                                    </div>
                                </h4>
                            </motion.div>
                        </motion.div>


                    <div className={`sections-overflow-elements ${(props.activeSection === 3 || props.activeSection === 7 ) ? "background-active" : ""}`}>
                            {function renderSectionFourBackground() {
                                if (props.prevActiveSection === 2 && props.activeSection === 3) {
                                    return <motion.div className={`white-background`} variants={animations.whiteBackgroundA} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}></motion.div>
                                }
                                if (props.prevActiveSection === 3 && props.activeSection === 2) {
                                    return <motion.div className={`white-background`} variants={animations.whiteBackgroundB} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}></motion.div>
                                }
                                if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                    return <motion.div className={`white-background`} variants={animations.whiteBackgroundC} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}></motion.div>
                                }
                                if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                    return <motion.div className={`white-background`} variants={animations.whiteBackgroundD} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}></motion.div>
                                }
                                return null;
                            }()}


                            {function renderSectionEightBackground() {

                                if (props.prevActiveSection === 6 && props.activeSection === 7) {
                                    return <motion.div className={`red-background`} variants={animations.redBackgroundA} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                    </motion.div>
                                }
                                if (props.prevActiveSection === 7 && props.activeSection === 6) {
                                    return <motion.div className={`red-background`} variants={animations.redBackgroundB} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                    </motion.div>
                                }
                                if (props.prevActiveSection === 7 && props.activeSection === 8) {
                                    return <motion.div className={`red-background`} variants={animations.redBackgroundC} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                    </motion.div>
                                }
                                if (props.prevActiveSection === 8 && props.activeSection === 7) {
                                    return <motion.div className={`red-background`} variants={animations.redBackgroundD} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                    </motion.div>
                                }
                                return null;
                            }()}

                            {function renderSectionSixArrow() {

                                if (props.prevActiveSection === 4 && props.activeSection === 5) {
                                    return  <motion.svg variants={animations.redArrowA} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                if (props.prevActiveSection === 5 && props.activeSection === 4) {
                                    return  <motion.svg variants={animations.redArrowB} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                if (props.prevActiveSection === 5 && props.activeSection === 6) {
                                    return  <motion.svg variants={animations.redArrowC} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                if (props.prevActiveSection === 6 && props.activeSection === 5) {
                                    return  <motion.svg variants={animations.redArrowD} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                if (props.prevActiveSection === 6 && props.activeSection === 7) {
                                    return  <motion.svg variants={animations.redArrowE} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                if (props.prevActiveSection === 7 && props.activeSection === 6) {
                                    return  <motion.svg variants={animations.redArrowF} initial={"initial"} animate={"animate"} transition={{ duration: 1, type: 'tween' }} className={"arrow"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.8 458"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={"#db121c"} className="cls-1" d="M0,0H64.07L222.52,210.39a31.23,31.23,0,0,1,.17,37.35L67.42,458H0Z"/></g></g></motion.svg>
                                }
                                return null;
                            }()}

                            {function renderSectionSixArrow() {

                                if (props.prevActiveSection === 2 && props.activeSection === 3) {
                                    return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                        <motion.img initial={{x: "-800px", opacity: 0}} animate={{x: 0, opacity: 1}} transition={{delay: .3 ,duration: 1, type: 'tween'}} src={man} alt=""/>
                                        <motion.img initial={{x: "-930px", opacity: 0}} animate={{x: 0, opacity: .6}} transition={{delay: .3, duration: 1, type: 'tween'}} src={woman} alt=""/>
                                        <motion.img initial={{x: "-1100px", opacity: 0}} animate={{x: 0, opacity: .4}} transition={{delay: .3, duration: 1, type: 'tween'}} src={man} alt=""/>
                                    </motion.div>                                }
                                if (props.prevActiveSection === 3 && props.activeSection === 2) {
                                    return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                        <motion.img initial={{x: 0, opacity: 1}} animate={{x: "-800px" , opacity: 0}} transition={{delay: 0 ,duration: 1, type: 'tween'}} src={man} alt=""/>
                                        <motion.img initial={{x: 0, opacity: .6}} animate={{x: "-930px" , opacity: 0}} transition={{delay: 0, duration: 1, type: 'tween'}} src={woman} alt=""/>
                                        <motion.img initial={{x: 0, opacity: .4}} animate={{x: "-1100px" , opacity: 0}} transition={{delay: 0, duration: 1, type: 'tween'}} src={man} alt=""/>
                                    </motion.div>                                }
                                if (props.prevActiveSection === 3 && props.activeSection === 4) {
                                    return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                        <motion.img initial={{x: 0, opacity: 1}} animate={{x: "1200px", opacity: 1}} transition={{delay: .1 ,duration: 1, type: 'tween'}} src={man} alt=""/>
                                        <motion.img src={woman} initial={{x: 0, opacity: .6}} animate={{x: "530px", opacity: .6}} transition={{delay: .1, duration: 1, type: 'tween'}} alt=""/>
                                        <motion.img initial={{x: 0, opacity: .4}} animate={{x: "-50px", opacity: .4}} transition={{delay: .1, duration: 1, type: 'tween'}} src={man} alt=""/>
                                    </motion.div>                                 }
                                if (props.prevActiveSection === 4 && props.activeSection === 3) {
                                    return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                        <motion.img initial={{x: "1200px", opacity: 1}} animate={{x: 0, opacity: 1}} transition={{delay: .1 ,duration: 1, type: 'tween'}} src={man} alt=""/>
                                        <motion.img src={woman} initial={{x: "530px", opacity: .6}} animate={{x: 0, opacity: .6}} transition={{delay: .1, duration: 1, type: 'tween'}} alt=""/>
                                        <motion.img initial={{x: "-50px", opacity: .4}} animate={{x: 0, opacity: .4}} transition={{delay: .1, duration: 1, type: 'tween'}} src={man} alt=""/>
                                    </motion.div>
                                    }
                                if (props.prevActiveSection === 4 && props.activeSection === 5) {
                                        return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                            <motion.img initial={{x: "1200px", opacity: 1}} animate={{x: "1300px", opacity: 0}} transition={{delay: .3 ,duration: 1, type: 'tween'}} src={man} alt=""/>
                                            <motion.img src={woman} initial={{x: "530px", opacity: .6}} animate={{x: "730px", opacity: 0}} transition={{delay: .3, duration: 1, type: 'tween'}} alt=""/>
                                            <motion.img initial={{x: "-50px", opacity: .4}} animate={{x: "150px", opacity: 0}} transition={{delay: .3, duration: 1, type: 'tween'}} src={man} alt=""/>
                                        </motion.div>
                                }
                                if (props.prevActiveSection === 5 && props.activeSection === 4) {
                                    return <motion.div className={"man"} variants={animations.whiteBackground} initial={"initial"} animate={"animate"} transition={{duration: 1, type: "tween"}}>
                                        <motion.img initial={{x: "1300px", opacity: 0}} animate={{x: "1200px", opacity: 1}} transition={{delay: .8, duration: 1, type: 'tween'}} src={man} alt=""/>
                                        <motion.img src={woman} initial={{x: "730px", opacity: 0}} animate={{x: "530px", opacity: .6}} transition={{delay: .8, duration: 1, type: 'tween'}} alt=""/>
                                        <motion.img initial={{x: "150px", opacity: 0}} animate={{x: "-50px", opacity: .4}} transition={{delay: .8, duration: 1, type: 'tween'}} src={man} alt=""/>
                                    </motion.div>
                                }
                                return null;
                            }()}


                    </div>
      </div>

  );
}

export default Sections;
