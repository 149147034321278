import React from 'react';
import Linkedin from '../../assets/images/linkedin.svg';
import linkedinGrey from '../../assets/images/linkedin-grey.svg';

function LinkedinIcon (props) {
    return (
        <a target="_blank" href={props.url}>
            <img className={'icon linkedin-icon'} src={props.greyIcon ? linkedinGrey : Linkedin } alt="Small icon of the linkedin logo in a white round."/>
        </a>
    )
}

export default LinkedinIcon