import React from 'react';
import {BrowserRouter, NavLink, Link} from 'react-router-dom';
import LinkedinIcon from "../LinkedinIcon/LinkedinIcon";

function FooterContent(props) {

    return (
        <footer >
            {props.logo ? (<svg onClick={props.goHome} id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 217.86 175.05">
                <path fill={'#1a282d'} className="cls-1"
                      d="M215.67,134.7h-42a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h42a2,2,0,0,1,2,2V132.7A2,2,0,0,1,215.67,134.7Zm-40-4h38V4h-38Z"/>
                <path fill={'#db121c'} className="cls-2"
                      d="M148.53,134.7H100a2,2,0,0,1-1.6-.8L82.7,112.74l2.52-3.32L101,130.7h43.53L113.31,88.61a16.57,16.57,0,0,0-26.59,0L53.11,133.9a2,2,0,0,1-1.61.8H3a2,2,0,0,1-1.6-3.19L49,67.35,1.39,3.19A2,2,0,0,1,3,0H51.5a2,2,0,0,1,1.61.81L86.72,46.09a16.56,16.56,0,0,0,26.59,0L144.55,4H101L85.22,25.28l-2.5-3.34L98.41.81A2,2,0,0,1,100,0h48.52a2,2,0,0,1,1.6,3.19L116.52,48.47a20.55,20.55,0,0,1-33,0L50.5,4H7L53.11,66.16a2,2,0,0,1,0,2.38L7,130.7H50.5l33-44.47a20.56,20.56,0,0,1,33,0l33.61,45.28a2,2,0,0,1-1.6,3.19Z"/>
                <path fill={'#1a282d'} className="cls-1" d="M0,156.89H9.79v3.33H6.56V174.6H3.14V160.22H0Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M19.43,156.89h3.42v6.68h5.91v-6.68h3.4V174.6h-3.4v-7.76H22.85v7.76H19.43Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M43.72,156.89h9.67v3.3H47.07v3.2h6.32v3.24H47.07v4.66h6.32v3.31H43.72Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M78.24,156.89h9.67v3.3H81.58v3.2h6.33v3.24H81.58v4.66h6.33v3.31H78.24Z"/>
                <path fill={'#1a282d'} className="cls-1" d="M98.92,156.89h3.37v14.5h4.92v3.21H98.92Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M117,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31H117Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M136,156.89h3.47L144,169.47l4.52-12.58h3.46l-6.38,17.71H142.3Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M161.77,156.89h9.67v3.3h-6.32v3.2h6.32v3.24h-6.32v4.66h6.32v3.31h-9.67Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M182.47,156.89h3.23l7.58,11.66V156.89h3.37V174.6h-3.24L185.84,163V174.6h-3.37Z"/>
                <path fill={'#1a282d'} className="cls-1"
                      d="M217.54,159.3l-2.49,2.2c-.88-1.22-1.77-1.83-2.68-1.83a1.63,1.63,0,0,0-1.09.36,1,1,0,0,0-.42.8,1.35,1.35,0,0,0,.3.84,23.79,23.79,0,0,0,2.47,2.28c1.29,1.07,2.07,1.75,2.34,2a7,7,0,0,1,1.46,2,5,5,0,0,1,.43,2.07,4.76,4.76,0,0,1-1.51,3.6,5.53,5.53,0,0,1-3.93,1.42,5.86,5.86,0,0,1-3.3-.93,7.42,7.42,0,0,1-2.4-2.91l2.83-1.71c.85,1.56,1.84,2.35,2.94,2.35a2.15,2.15,0,0,0,1.46-.51,1.5,1.5,0,0,0,.59-1.17,2,2,0,0,0-.44-1.2,12.49,12.49,0,0,0-2-1.84,20.1,20.1,0,0,1-3.74-3.65,4.61,4.61,0,0,1-.84-2.55,4.18,4.18,0,0,1,1.4-3.16,4.88,4.88,0,0,1,3.47-1.32,5.45,5.45,0,0,1,2.52.61A9.24,9.24,0,0,1,217.54,159.3Z"/>
            </svg>) : ''}
            <LinkedinIcon greyIcon={props.greyIcon} url={'https://www.linkedin.com/company/72570892/admin/'}/>
            <p>2021 by The Elevens. All rights reserved.</p>

        </footer>
    );

}

export default FooterContent