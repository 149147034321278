import React, {useState, useEffect} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import animations from "../../helpers/animations";



const principleArray = [
    {id: 1, number: 'I', principle: 'We play the game.', content: 'Enthusiastic and full of confidence by nature, we are always open to new business\n' +
            'opportunities. When we get a chance, we take the shot.'},
    {id: 2, number: 'II', principle: `We have each other's back.`, content: 'Based on true team spirit, we believe that supporting and pushing each other forward is a\n' +
            'matter of course. The true power of our team effort.'},
    {id: 3, number: 'III', principle: 'We dream big.', content: 'The ability to dream big creates a unique vision. Our reach should always be further than\n' +
            'our grasp.'},
    {id: 4, number: 'IV', principle: 'We celebrate together.', content: 'As we strive together, we cheer for each individual’s success. Each victory is a win for the team.'},
    {id: 5, number: 'V', principle: `We don't live by rules.`, content: 'Being united leads to full independency. It provides us with the strength and confidence\n' +
            'to make our own decisions. We are not led by the rules of others.'},
    {id: 6, number: 'VI', principle: 'We create our own roads to success.', content: 'Encouraged by our entrepreneurial and innovative mindset, we are always looking for new\n' +
            'ways to make a difference. We don’t follow the crowd. We lead the way.'},
    {id: 7, number: 'VII', principle: 'We strive for authenticity.', content: 'Our decisions are based on the confidence and conviction that staying true to what we\n' +
            'truly believe is the best for achieving our goals.'},
    {id: 8, number: 'VIII', principle: 'We engage with each other.', content: 'We make an effort to understand and challenge each other on a basis of mutual respect.'},
    {id: 9, number: 'IX', principle: 'People are awesome.', content: 'People make all the difference. Not the process.'},
    {id: 10, number: 'X', principle: `We’re stronger together.`, content: 'Together we know and see more. By uniting forces we utilize each other’s strengths and\n' +
            'weaknesses.'},
    {id: 11, number: 'XI', principle: 'One plus one is Eleven.', content: 'Our awesome multiplier of talent, knowledge and expertise.'},
];


function Principles(props) {
    let [activePrincipleIndex, setActivePrincipleIndex] = useState(0);
    let [intervalRef, setIntervalRef] = useState(null);

    let [principleTitle, setPrincipleTitle] = useState(principleArray[0].principle);
    let [principleContent, setPrincipleContent] = useState(principleArray[0].content);


    useEffect(() => {
        
        // Start interval
        if (props.trigger) {
            setIntervalRef(setInterval(handleInterval, 5000));
        }

        return () => {
            if (intervalRef) {
                clearInterval(intervalRef);
                intervalRef = null;
                setIntervalRef(null);
            }
        }
    }, [props.trigger]);

    const handleInterval = () => {
        if (activePrincipleIndex >= (principleArray.length - 1)) {
            activePrincipleIndex = 0;
        } else {
            activePrincipleIndex++;
        }
        setActivePrincipleIndex(activePrincipleIndex);
        setPrincipleContent(principleArray[activePrincipleIndex].content)
        setPrincipleTitle(principleArray[activePrincipleIndex].principle)
    };

    const handlePrincipleClick = (i) => {
        if (intervalRef) {
            clearInterval(intervalRef);
            intervalRef = null;
            setIntervalRef(null);
        }
        activePrincipleIndex = i;
        setActivePrincipleIndex(activePrincipleIndex);
        setPrincipleContent(principleArray[activePrincipleIndex].content)
        setPrincipleTitle(principleArray[activePrincipleIndex].principle)
    };

    return (
        <div style={props.trigger ? { pointerEvents: 'initial' } : { pointerEvents: 'none' }} className={`section-8 principles active-section-overflow`} id={'principles'}>
            <motion.div variants={function() {
                if (props.prevActiveSection === 7 && (props.activeSection === 6 || props.activeSection === 8)) {
                    return {
                        initial: {
                            opacity: 1,
                            transition: {
                                delay: 0,
                            },
                        },
                        animate: {
                            opacity: 0,
                            transition: {
                                delay: 0,
                            },
                        },
                    }
                }
                if (props.activeSection === 7) {
                    return {
                        initial: {
                            opacity: 0,
                            transition: {
                                delay: .6,
                            },
                        },
                        animate: {
                            opacity: 1,
                            transition: {
                                delay: .6,
                            },
                        },
                    }
                }
                return {
                    initial: {
                        opacity: 0,
                        transition: {
                            delay: 0,
                        },
                    },
                    animate: {
                        opacity: 0,
                        transition: {
                            delay: 0,
                        },
                    },
                };
            }()} initial="initial" animate="animate" className="principles__inner" transition={{ type: 'tween' }}>
                        <div className="principles__content" >
                            <h3 >Our principles.</h3>
                            <p className={"subtitle"}>We have eleven principles that we live and work by.</p>
                            <p>
                                We believe that these principles are the foundation of our alliance, providing guidance to
                                the challenges that we face. They are the strategy behind our game plan. The backbone
                                of our success.
                            </p>
                        </div>
                <div className="principles__numcontainer">
                    <div className={`principles__numcontainer--line ${props.trigger ? "fill" : ''}`}>

                    </div>
                    <motion.ul variants={animations.principleStagger} initial={"hidden"} animate={props.trigger ? "visible" : ''}   className="principles__numbers">
                        {principleArray.map((principle, i) => {
                            const isActive = i === activePrincipleIndex;
                            return (
                                <motion.li variants={animations.principleItemAnim} key={principle.id}>
                                    <div onClick={() => { handlePrincipleClick(i); }}>
                                        <div className={`prin-number ${isActive ? 'active' : null}`}>
                                            {principle.number}
                                        </div>
                                        {/*<div className={`principles__line ${isActive ? 'active' : null}`}>*/}
                                        {/*</div>*/}

                                    </div>
                                </motion.li>
                            )
                        })
                        }
                    </motion.ul>
                </div>
                <motion.div key={principleTitle} variants={animations.fade} initial="initial" animate="animate" exit="exit" className={`principles__principle`}>
                    <h3>
                        {principleTitle}
                    </h3>
                    <p>
                        {principleContent}
                    </p>
                </motion.div>

            </motion.div>
        </div>
    )
}

export default Principles