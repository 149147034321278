import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import animations from "../../helpers/animations";


const SectionThreeCurves = (props) => {

    const drawPaths = {
        initial: {
            opacity: 1,
            pathLength: 0,
            pathOffset: 1
        },
        animate: {
            pathLength: 1,
            pathOffset: 0
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0,
                duration: .7
            }
        }
    }

    const fade = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0,
                duration: .7
            }
        }
    }

    return (

        <div className={"curves"}>
            <AnimatePresence>
                {props.trigger ? (
                    <svg className={"line"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.16 241.34">
                        <g id="Laag_2" data-name="Laag 2">
                            <g id="Laag_3" data-name="Laag 3">
                                <motion.path variants={drawPaths} initial={"initial"} animate={props.trigger ? "animate" : ''} exit={'exit'} transition={{delay: .3, duration: 5 }} fill={'none'} stroke={"#db151d"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,232.36,163.81,29.26a1.8,1.8,0,0,0-.12-2.28l-15-17.07"/>
                            </g>
                        </g>
                    </svg>
                ) : "" }
            </AnimatePresence>

            <AnimatePresence>
                {props.trigger ? (
                    <svg className={"bigcurve"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 545.7 417.57">

                        <g id="Laag_2" data-name="Laag 2">
                            <g id="Laag_1-2" data-name="Laag 1">
                                <motion.path variants={fade} initial={"initial"} animate={props.trigger ? "animate" : ''} exit={'exit'} transition={{ delay: .6, duration: .7 }} fill={'none'} stroke={"#db151d"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1"
                                      d="M12.12,315.3,182.91,80.88s44.38-65.3,112.19-65.3c0,0,69.49-9.21,111.35,46S524.39,225.25,524.39,225.25s25.79,29.75-26.87,98.51c-53.58,69.95-97.66,74.39-129.86,78.21-90.14,10.72-167.16-127.7-167.16-127.7l-17.31-26.79"/>
                            </g>
                        </g>
                    </svg>
                ) : "" }
            </AnimatePresence>
        </div>
    )
};

export default SectionThreeCurves