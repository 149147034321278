import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import animations from "../../helpers/animations";


const SectionTwoCurves = (props) => {



    const sectionTwoHalfPath = {
        initial: {
            opacity: 1,
            pathLength: .5,
            pathOffset: 1
        },
        animate: {
            pathLength: 1,
            pathOffset: 0
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0,
                duration: .7
            }
        }
    }

    const fade = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0,
                duration: .7
            }
        }
    }

    return (

        <div className={"curves"}>

            <AnimatePresence>
                {props.trigger ? (
                    <svg className={"bigcurve"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 422.96 276.5">
                        <g data-name="Laag 2">
                            <motion.path variants={sectionTwoHalfPath} initial={"initial"} animate={props.trigger ? "animate" : ''} exit={"exit"} transition={{ duration: 7, type: "tween", ease: "easeInOut"}} fill="none" stroke="#212d33" strokeMiterLimit="10" strokeWidth="24px" data-name="Laag 1" d="M12 9l152.37 203.76a125.8 125.8 0 0040.2 35.24A104.77 104.77 0 00234 259a127.34 127.34 0 0038.27 1.85C290 259 313 250.22 333.36 228.67a106 106 0 008-9.43c20.37-27.34 69.49-94 69.49-94" />
                        </g>
                    </svg>
                ) : "" }
            </AnimatePresence>

            <AnimatePresence>
                {props.trigger ? (
                    <svg className={"small-line"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.31 87.15">
                        <g data-name="Laag 2">
                            <motion.path variants={fade} initial={"initial"} animate={props.trigger ? "animate" : ''} transition={{delay: .5, duration: 1  }} exit={'exit'} fill="none" stroke="#222e33" strokeMiterLimit="10" strokeWidth="30px" d="M63.24 8.91L12.07 78.24" data-name="Laag 1"/>
                        </g>
                    </svg>
                ) : "" }
            </AnimatePresence>

            <AnimatePresence>
                {props.trigger ? (
                    <svg className={"line"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.16 241.34">

                        <g id="Laag_2" data-name="Laag 2">
                            <g id="Laag_3" data-name="Laag 3">
                                <motion.path variants={fade} initial={"initial"} animate={props.trigger ? "animate" : ''} transition={{delay: .5, duration: 1  }} exit={'exit'} fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l151.79,203.1a1.8,1.8,0,0,1-.12,2.28l-15,17.07"/>
                            </g>
                        </g>
                    </svg>
                ) : "" }
            </AnimatePresence>



        </div>
    )
};

export default SectionTwoCurves