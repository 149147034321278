const bottomTextAnimation = {
    hidden: {
        y: '150px'
    },
    visible: {
        y: 0
    }
}

const bottomSmallTextAnimation = {
    hidden: {
        opacity: 0,
        y: '50px'
    },
    visible: {
        opacity: 1,
        y: 0
    }
}

const topTextAnimation = {
    hidden: {
        opacity: 0,
        y: '-150px'
    },
    visible: {
        opacity: 1,
        y: 0
    }
}

const countingAnimation = {
    initial: {
        y: 0
    },
    count: {
        y: '-1260px'
    }
}

const topSmallTextAnimation = {
    hidden: {
        opacity: 0,
        y: '-50px'
    },
    visible: {
        opacity: 1,
        y: 0
    }
}

const leftTextAnimation = {
    hidden: {
        x: '-150px'
    },
    visible: {
        x: 0
    }
}




const arrowSwoosh = {
    initial: {
        x: "-100%",
        y: "-50%",
        opacity: 0
    },
    animate: {
        x: "300%",
        y: "-50%",
        opacity: 1
    }
}



const rightTextAnimation = {
    hidden: {
        x: '150px'
    },
    visible: {
        x: 0
    }
}

const principleStagger = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.1,
        }
    }
}

const mathStagger = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.3,
        }
    }
}

const simpleStagger = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.1,
        }
    }
}



const principleItemAnim = {
    hidden: {
        opacity: 0,
        x: '-44px'
    },
    visible: {
        opacity: 1,
        x: 0
    }
}

const peopleBackAnimA = {
    initial: {
        x: '0'
    },
    animate: {
        x: '0'
    }
}

const peopleBackAnimB = {
    initial: {
        x: '0'
    },
    animate: {
        x: '0'
    }
}

const peopleBackAnimC = {
    initial: {
        x: '0'
    },
    animate: {
        x: '0'
    }
}

const peopleBackAnimD = {
    initial: {
        x: '0'
    },
    animate: {
        x: '0'
    }
}

const drawPaths = {
    initial: {
        pathLength: 0,
        pathOffset: 1
    },
    animate: {
        pathLength: 1,
        pathOffset: 0
    }
}

const blushAnim = {
    initial: {
        color: '#1a292e'
    },
    animate: {
        color: '#da121c'
    }
}

const contentBlockItemAnimation = {
    hidden: {
        opacity: 0,
        y: "-40px"
    },
    visible: {

        transition: 'tween',
        opacity: 1,
        y: 0,

    }
}

const fade = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    }
}

//section1 animations
const sectionOneText = {
    initial: {
        y: '300%',
        opacity: 0,

    },
    animate: {
        y: '0',
        opacity: 1,


    },
    animateOut: {
        y: '-300%',
        opacity: 0
    }
}

//section 2 animations
const sectionTwoText = {
    initial: {
        y: '200px',
        opacity: 0
    },
    animate: {
        y: 0,
        opacity: 1
    },
    animateOut: {
        y: '-200px',
        opacity: 0,
        transition: {
            duration: 1,
            delay: 0
        }
    }
}

//section 3 animations

const sectionThreeText = {
    initial: {
        opacity: 0,
        y: "200px"
    },
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1,
            delay: 0
        }
    }
}


//section 4 animations


const sectionFourTextA = {
    initial: {
        y: "400px",
        opacity: 1,

    },
    animate: {
        y: 0,
        opacity: 1,
    },
}

const sectionFourTextB = {
    initial: {
        y: 0,
        opacity: 1,

    },
    animate: {
        y: "400px",
        opacity: 0,
    },
}

const sectionFourTextC = {
    initial: {
        y: 0,
        x: 0,
        opacity: 1,

    },
    animate: {
        y: 0,
        x: "400px",
        opacity: 0,
    },
}

const sectionFourTextD = {
    initial: {
        y: 0,
        x: "400px",
        opacity: 0,

    },
    animate: {
        y: 0,
        x: 0,
        opacity: 1,
    }
}


const whiteBackgroundA = {
    initial: {
        opacity: 1,
        y: "100%",
        backgroundColor: "#ffffff",
        x: 0,
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#ffffff',
    },
}

const whiteBackgroundB = {
    initial: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#ffffff',
    },
    animate: {
        opacity: 1,
        y: "100%",
        x: 0,
        backgroundColor: '#ffffff',
    },
}

const whiteBackgroundC = {
    initial: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#ffffff',
    },
    animate: {
        opacity: 0,
        y: 0,
        x: 0,
        backgroundColor: '#ffffff',
    },
}

const whiteBackgroundD = {
    initial: {
        opacity: 0,
        y: 0,
        x: "100%",
        backgroundColor: '#ffffff',
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#ffffff',
    },
}


//section 5 animations
const sectionFiveTextA = {
    initial: {
        opacity: 0,
        x: '-100%'
    },
    animate: {
        opacity: 1,
        x: 0
    },
}

const sectionFiveTextB = {
    initial: {
        opacity: 1,
        x: 0
    },
    animate: {
        opacity: 0,
        x: '-100%'
    },
}

const sectionFiveTextC = {
    initial: {
        opacity: 1,
        x: 0
    },
    animate: {
        opacity: 0,
        x: "200%"
    },
}

const sectionFiveTextD = {
    initial: {
        opacity: 0,
        x: "200%"
    },
    animate: {
        opacity: 1,
        x: 0,
    },
}


const sectionFiveArrowA = {
    initial: {
        x: "-300%",
        y: "-50%",
        opacity: 1,

    },
    animate: {
        x: "0",
        y: "-50%",
        opacity: 1,
    }
}

const sectionFiveArrowB = {
    initial: {
        x: "0",
        y: "-50%",
        opacity: 1,
    },
    animate: {
        x: "-300%",
        y: "-50%",
        opacity: 1,
    }
}


const sectionFiveArrowC = {
    initial: {
        x: "0",
        y: "-50%",
        opacity: 1,
    },
    animate: {
        x: "400%",
        y: "-50%",
        opacity: 0,
    }
}


const sectionFiveArrowD = {
    initial: {
        opacity: 0,
        x: "400%",
        y: "-50%"
    },
    animate: {
        x: "0",
        y: "-50%",
        opacity: 1,
    }
}



//section 6 animations

const sectionSixTextA = {
    initial: {
        opacity: 0,
        x: "-200%"
    },
    animate: {
        opacity: 1,
        x: 0
    },
}

const sectionSixTextB = {
    initial: {
        opacity: 1,
        x: 0
    },
    animate: {
        opacity: 0,
        x: "-200%"
    },
}

const sectionSixTextC = {
    initial: {
        opacity: 1,
        x: 0
    },
    animate: {
        opacity: 0,
        x: "0"
    },
}

const sectionSixTextD = {
    initial: {
        opacity: 0,
        x: "0"
    },
    animate: {
        opacity: 1,
        x: 0
    },
}


const sectionSixCurveTopA = {
    initial: {
        opacity: 0,
        scale: 1,
        x: "-200%",
        y: "-100px",
    },
    animate: {
        opacity: 1,
        scale: 1,
        x: "-50%",
        y: "-100px"
    }
}

const sectionSixCurveTopB = {
    initial: {
        opacity: 1,
        x: "-50%",
        y: "-100px",
        scale: 1
    },
    animate: {
        opacity: 0,
        x: "-200%",
        scale: 1,
        y: "-100px"
    }
}

const sectionSixCurveTopC = {
    initial: {
        opacity: 1,
        x: "-50%",
        y: "-100px",
        scale: 1,
    },
    animate: {
        opacity: 1,
        x: "50%",
        y: "-200px",
        scale: 1.7
    }
}

const sectionSixCurveTopD = {
    initial: {
        opacity: 1,
        x: "50%",
        y: "-200px",
        scale: 1.7,
    },
    animate: {
        opacity: 1,
        x: "-50%",
        y: "-100px",
        scale: 1
    }
}

const sectionSixCurveTopE = {
    initial: {
        opacity: 1,
        x: "50%",
        y: "-200px",
        scale: 1.7,
    },
    animate: {
        opacity: 0,
        x: "50%",
        y: "-200px",
        scale: 1.7,
    }
}

const sectionSixCurveTopF = {
    initial: {
        opacity: 0,
        x: "50%",
        y: "-200px",
        scale: 1.7,
    },
    animate: {
        opacity: 1,
        x: "50%",
        y: "-200px",
        scale: 1.7,
    }
}


const sectionSixCurveBottomA = {
    initial: {
        opacity: 0,
        scale: 1,
        x: "-200%",
        y: "100px",
    },
    animate: {
        opacity: 1,
        scale: 1,
        x: "-50%",
        y: "100px"
    }
}

const sectionSixCurveBottomB = {
    initial: {
        opacity: 1,
        x: "-50%",
        y: "100px",
        scale: 1
    },
    animate: {
        opacity: 0,
        x: "-200%",
        scale: 1,
        y: "100px"
    }
}

const sectionSixCurveBottomC = {
    initial: {
        opacity: 1,
        x: "-50%",
        y: "100px",
        scale: 1,
    },
    animate: {
        opacity: 1,
        x: "50%",
        y: "20px",
        scale: 1.4
    }
}

const sectionSixCurveBottomD = {
    initial: {
        opacity: 1,
        x: "50%",
        y: "20px",
        scale: 1.4,
    },
    animate: {
        opacity: 1,
        x: "-50%",
        y: "100px",
        scale: 1
    }
}
const sectionSixCurveBottomE = {
    initial: {
        opacity: 1,
        x: "50%",
        y: "20px",
        scale: 1.4,
    },
    animate: {
        opacity: 0,
        x: "50%",
        y: "20px",
        scale: 1.4,
    }
}

const sectionSixCurveBottomF = {
    initial: {
        opacity: 0,
        x: "50%",
        y: "20px",
        scale: 1.4,
    },
    animate: {
        opacity: 1,
        x: "50%",
        y: "20px",
        scale: 1.4,
    }
}



//section 7 animations
const sectionSevenTextA = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    }
}

const sectionSevenTextB = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 0,
    }
}

const sectionSevenTextC = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 0,
    }
}

const sectionSevenTextD = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    }
}


//principle animations/section 8
const sectionEightAnim = {
    initial: {
        x: '-100%'
    },
    animate: {
        x: 0
    },
    exit: {
        x: "100%"
    }
}

const sectionEightTextA = {
    initial: {
        opacity: 0,
        y: "100px"
    },
    animate: {
        opacity: 1,
        y: 0
    },

}

const sectionEightTextB = {
    initial: {
        opacity: 1,
        y: "0"
    },
    animate: {
        opacity: 0,
        y: "100px"
    },

}

const sectionEightTextC = {
    initial: {
        opacity: 1,
        y: "0"
    },
    animate: {
        opacity: 0,
        y: "100px"
    },

}

const sectionEightTextD = {
    initial: {
        opacity: 0,
        y: "100px"
    },
    animate: {
        opacity: 1,
        y: 0
    },

}

const redBackgroundA = {
    initial: {
        opacity: 1,
        y: 0,
        x: "-100%",
        backgroundColor: '#db121c',
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
}

const redBackgroundB = {
    initial: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
    animate: {
        opacity: 1,
        y: 0,
        x: "-100%",
        backgroundColor: '#db121c',
    },
}
const redBackgroundC = {
    initial: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
    animate: {
        opacity: 0,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
}

const redBackgroundD = {
    initial: {
        opacity: 0,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        backgroundColor: '#db121c',
    },
}


const redArrowA = {
    initial: {
        x: "-100%",
    },
    animate: {
        x: "-60%",
    }
}

const redArrowB = {
    initial: {
        x: "-60%",
    },
    animate: {
        x: "-100%",
    }
}

const redArrowC = {
    initial: {
        x: "-60%",
    },
    animate: {
        x: "0",
    }
}

const redArrowD = {
    initial: {
        x: "0",
    },
    animate: {
        x: "-60%",
    }
}

const redArrowE = {
    initial: {
        x: "0",
    },
    animate: {
        x: "100vw",
    }
}

const redArrowF = {
    initial: {
        x: "100vw",
    },
    animate: {
        x: "0",
    }
}


export default {fade, redArrowA, redArrowB, redArrowC, redArrowD, redArrowE, redArrowF, redBackgroundA, redBackgroundB, redBackgroundC, redBackgroundD,  whiteBackgroundA, whiteBackgroundB, whiteBackgroundC, whiteBackgroundD, sectionFourTextA, sectionFourTextB, sectionFourTextC, sectionFourTextD, sectionOneText, sectionTwoText, sectionThreeText, sectionFiveTextA, sectionFiveTextB, sectionFiveTextC, sectionFiveTextD, sectionFiveArrowA, sectionFiveArrowB, sectionFiveArrowC, sectionFiveArrowD, sectionSixTextA, sectionSixTextB, sectionSixTextC, sectionSixTextD, sectionSixCurveTopA, sectionSixCurveTopB, sectionSixCurveTopC, sectionSixCurveTopD,sectionSixCurveTopE, sectionSixCurveTopF, sectionSixCurveBottomA, sectionSixCurveBottomB, sectionSixCurveBottomC, sectionSixCurveBottomD, sectionSixCurveBottomE, sectionSixCurveBottomF, sectionSevenTextA, sectionSevenTextB, sectionSevenTextC, sectionSevenTextD, sectionEightAnim, sectionEightTextA, sectionEightTextB, sectionEightTextC, sectionEightTextD, mathStagger, blushAnim,arrowSwoosh, rightTextAnimation, peopleBackAnimA, peopleBackAnimB, peopleBackAnimC, peopleBackAnimD, drawPaths, leftTextAnimation, topSmallTextAnimation, topTextAnimation, bottomSmallTextAnimation, bottomTextAnimation, principleStagger, contentBlockItemAnimation, principleItemAnim, simpleStagger, countingAnimation};