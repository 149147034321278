import React, { useRef, useEffect } from 'react';
import rotate from '../../assets/images/rotate.png';
import logo from '../../assets/images/logo-length.png';

const DeviceNotSupported = () => {
    const deviceNotSupportedRef = useRef(null);
    const landscapeViewInnerRef = useRef(null);
    const reallySmallScreenInnerRef = useRef(null);

    const handleViewportChange = () => {
        const mobileViewport = window.innerWidth < 769;
        const isLandscape = window.innerWidth > window.innerHeight;
        const reallySmallScreen = window.innerWidth < 325;

        if (deviceNotSupportedRef && deviceNotSupportedRef.current) {
            if (mobileViewport) {

                if (isLandscape || reallySmallScreen) {
                    deviceNotSupportedRef.current.classList.remove("hidden");
                } else {
                    deviceNotSupportedRef.current.classList.add("hidden");
                }

                if (landscapeViewInnerRef && landscapeViewInnerRef.current && reallySmallScreenInnerRef && reallySmallScreenInnerRef.current) {
                    if (isLandscape) {
                        landscapeViewInnerRef.current.classList.remove("hidden");
                        reallySmallScreenInnerRef.current.classList.add("hidden");
                    } else if (reallySmallScreen) {
                        landscapeViewInnerRef.current.classList.add("hidden");
                        reallySmallScreenInnerRef.current.classList.remove("hidden");
                    }
                }

            } else {
                deviceNotSupportedRef.current.classList.add("hidden");
            }
        }
    };

    useEffect(() => {
        handleViewportChange();
        window.addEventListener('resize', handleViewportChange);
        return () => {
            window.removeEventListener('resize', handleViewportChange);
        };
    }, []);

    return (
        <div className="device-not-supported-view hidden" ref={deviceNotSupportedRef}>
            <div className="device-not-supported-view__inner">

                {/* Landscape view */}
                <div ref={landscapeViewInnerRef} className="landscape-view-inner hidden">
                    <img src={rotate} alt=""/>
                    <h3>Please rotate your device.</h3>
                    <p>This website is viewed best in portrait mode.</p>
                    <img className={"logo"} src={logo} alt=""/>
                </div>

                {/* Really small screen view */}
                <div ref={reallySmallScreenInnerRef} className="really-small-screen-inner hidden">
                    <h3>This device is not supported.</h3>
                    <p>This website is viewed best on desktop.</p>
                    <img className={"logo"} src={logo} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default DeviceNotSupported;
