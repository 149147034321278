import React, {useState, useEffect} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {Section} from 'react-scroll-section';
import animations from "../../helpers/animations";
import man from '../../assets/images/downie.png';
import cumber from '../../assets/images/cumber.jpg';
import margo from '../../assets/images/margo.jpg';
import ironman from '../../assets/images/ironman.jpg';
import manArt from '../../assets/images/red-face.png';




import lutzen from '../../assets/images/people/Lutzen01.jpg';
import lutzenArt from '../../assets/images/people/art/Lutzen_Halftone.png';
import lutzenSmall from '../../assets/images/people/smallart/lutzen.png';
import lutzenCut from '../../assets/images/people/lutzen-cutout.png';

import bas from '../../assets/images/people/Bas02.jpg';
import basArt from '../../assets/images/people/art/bas_halftone.png';
import basSmall from '../../assets/images/people/smallart/bas.png';
import basCut from '../../assets/images/people/bas-cutout.png';

import eva from '../../assets/images/people/Eva01.jpg';
import evaArt from '../../assets/images/people/art/eva_halftone.png';
import evaSmall from '../../assets/images/people/smallart/eva.png';
import evaCut from '../../assets/images/people/eva-cutout.png';

import vincent from '../../assets/images/people/Vincent02.jpg';
import vincentArt from '../../assets/images/people/art/Vincent_Halftone.png';
import vincentSmall from '../../assets/images/people/smallart/vincent.png';
import vincentCut from '../../assets/images/people/Vincent-cutout.png';

import martijn from '../../assets/images/people/Martijn01.jpg';
import martijnArt from '../../assets/images/people/art/martijn.png';
import martijnSmall from '../../assets/images/people/smallart/martijn.png';
import martijnCut from '../../assets/images/people/martijn-cutout.png';

import gijsCut from '../../assets/images/people/gijs.png';
import gijsSmall from '../../assets/images/people/smallart/gijs.png';
import gijsArt from '../../assets/images/people/art/halftone_gijs.png';
import gijs from '../../assets/images/people/gijs02.png'

import casperCut from '../../assets/images/people/casper.png';
import casperSmall from '../../assets/images/people/smallart/casper.png';
import casperArt from '../../assets/images/people/art/halftone_casper.png';
import casper from '../../assets/images/people/casper02.png'

import gerjanCut from '../../assets/images/people/gerjan.png';
import gerjanSmall from '../../assets/images/people/smallart/gerjan.png';
import gerjanArt from '../../assets/images/people/art/halftone_gerjan.png';
import gerjan from '../../assets/images/people/gerjan02.png'

import joelCut from '../../assets/images/people/joel.png';
import joelSmall from '../../assets/images/people/smallart/joel.png';
import joelArt from '../../assets/images/people/art/halftone_joel.png';
import joel from '../../assets/images/people/joel02.png'


import leonieCut from '../../assets/images/people/leonie.png';
import leonieSmall from '../../assets/images/people/smallart/leonie.png';
import leonieArt from '../../assets/images/people/art/halftone_leonie.png';
import leonie from '../../assets/images/people/leonie02.png'

import saraCut from '../../assets/images/people/sara.png';
import saraSmall from '../../assets/images/people/smallart/sara.png';
import saraArt from '../../assets/images/people/art/halftone_sara.png';
import sara from '../../assets/images/people/sara02.png'

import mirjamCut from '../../assets/images/people/mirjam.png';
import mirjamSmall from '../../assets/images/people/smallart/mirjam.png';
import mirjamArt from '../../assets/images/people/art/halftone_mirjam.png';
import mirjam from '../../assets/images/people/mirjam02.png'

import background from '../../assets/images/people/background.png';

import greenArt from '../../assets/images/green-face.png';
import LinkedinIcon from "../LinkedinIcon/LinkedinIcon";
import SectionNineCurves from "../Curves/SectionNineCurves";




const peopleArr = [
    {id: 7, name: 'Gerjan', content: 'Perfect combination of HR experience and job placement. Discover how these worlds come together.\n' + '\nFollow Gerjan.', short:'Perfect combination of HR experience and job placement. Discover how these worlds come together.\n' + '\nFollow Gerjan.', image: gerjan, small: gerjanSmall, artwork: gerjanArt, cut: gerjanCut, linkedinUrl: 'https://www.linkedin.com/in/gerjan-lomulder-75435520/' },
    {id: 2, name: 'Bas', content: 'A result-driven multitasker with a wide range of interests. Out-of-the-box thinking and creative solutions define Bas. People-minded as he is, he will always be a true connector. \n' + '\n He believes in the power of being different. Shaking things up. Getting to the next level by sharing experience. He is confident The Elevens have the ability to change the employment and labour market by setting an example.', short:"A result-driven multitasker with a wide range of interests. Out-of-the-box thinking and creative solutions define Bas. People-minded as he is, he will...", image: bas, small: basSmall, artwork: basArt, cut: basCut, linkedinUrl: 'https://www.linkedin.com/in/baskingma/' },
    {id: 9, name: 'Leonie', content: 'Incredibly driven by people and business. The best of both worlds.\n' +  '\nGet to know Leonie.', short:'Incredibly driven by people and business. The best of both worlds.\n' +  '\nGet to know Leonie.', image: leonie, small: leonieSmall, artwork: leonieArt, cut: leonieCut, linkedinUrl: 'https://www.linkedin.com/in/leonie-karsten-87028a2b/' },
    {id: 1, name: 'Martijn ', content: 'Martijn plays to win. Simply doing your job is not enough. You have to excel. He is critical and honest to himself and others. He expects the best from anyone. Result-driven and with tremendous teamspirit. Empowering people. Inspiring them. Making sure they reach their full potential.  \n' + '\n Two see more than one. Sharing knowledge will make it multiply. Martijn believes that this is what The Elevens stand for. Empowering each other whilst being independent. Making sure everybody wins. Because together you’re not alone.', short:"Martijn plays to win. Simply doing your job is not enough. You have to excel. He is critical and honest to himself and others. He...", image: martijn, small: martijnSmall, artwork: martijnArt, cut: martijnCut, linkedinUrl: 'https://www.linkedin.com/in/martijnbakkerfinanxe/' },
    {id: 4, name: 'Vincent', content: 'Head-on. Full force. No doubt. When you want to get the job done you just do it. This mentality has gotten him to where he is today. Being united leads to strength and independence. He believes that setting people free will draw them closer. Increasing their commitment. \n' + '\n Strong-minded as he is, he will never avoid a good competition. Always one step ahead of the game. His knowledge and experience make him a great leader. He tackles problems with a fresh pair of eyes. More focussed on the solution than the problem.', short:"Head-on. Full force. No doubt. When you want to get the job done you just do it. This mentality has gotten him to where he is today. Being...", image: vincent, small: vincentSmall, artwork: vincentArt, cut: vincentCut, linkedinUrl: 'https://www.linkedin.com/in/loslatenishetnieuwevasthouden/' },
    {id: 5, name: 'Lútsen', content: 'Sustainability. In connections and progress. Lútsen believes in both. His power lies in his transparency. He is an open book. This is how he builds connections, and being connected will make you win. This fuels his winning mentality. But, you don’t win alone. Allies are very important to him. Like-minded people. He believes that’s how connections grow. \n' + '\n Openness, transparency and the desire to make people reach their full potential will make you accelerate. If you want to go fast, go alone. If you want to go faster, go together.', short:"Sustainability. In connections and progress. Lútsen believes in both. His power lies in his transparency. He is an open book. Transparency...", image: lutzen, small: lutzenSmall, artwork: lutzenArt, cut: lutzenCut, linkedinUrl: 'https://www.linkedin.com/in/lutsenzijlstra/' },
    // {id: 3, name: 'Eva', content: 'Set the bar high - do the best you can. Improve yourself everyday. Because today you can do better than yesterday. Critically but justly, Eva doesn’t settle for less. \n' + '\n She runs our back office proudly, facing challenges with pride. What fuels her energy? Working with different people and making them connect. Making sure a team reaches its full potential. Eva believes that you amplify each other by uniting forces as unique individuals. Being different is an opportunity.', short:"", image: eva, small: evaSmall, artwork: evaArt, cut: evaCut, linkedinUrl: 'https://www.linkedin.com/in/evavdveeken/' },
    {id: 10, name: 'Sara', content: 'She makes people and organizations flourish in a personal, professional and warm way. With a touch of humor. \n' + '\n Explore how, follow Sara.', short:'She makes people and organizations flourish in a personal, professional and warm way. With a touch of humor. \n' + '\n Explore how, follow Sara.', image: sara, small: saraSmall, artwork: saraArt, cut: saraCut, linkedinUrl: 'https://www.linkedin.com/in/saraplaut/' },
    {id: 3, name: 'Casper', content: 'Inspiring people to discover the world. Wondering how?\n' + '\nExplore it, follow Casper on LinkedIn.', short:'Inspiring people to discover the world. Wondering how?\n' + '\nExplore it, follow Casper on LinkedIn.', image: casper, small: casperSmall, artwork: casperArt, cut: casperCut, linkedinUrl: 'https://www.linkedin.com/in/casper-lemmen-658bb95b/' },
    {id: 6, name: 'Gijs', content: 'Be staunch, walk tall. Wondering how Gijs applies this in his life and work?\n' + '\n Connect with Gijs.', short:'Be staunch, walk tall. Wondering how Gijs applies this in his life and work?\n' + '\n Connect with Gijs.', image: gijs, small: gijsSmall, artwork: gijsArt, cut: gijsCut, linkedinUrl: 'https://www.linkedin.com/in/gijs-kingma-35445431/' },
    {id: 11, name: 'Mirjam', content: "Inspire women to achieve their ambitions. Curious about Mirjam's story? \n" + "\n Get to know her better, follow her on LinkedIn.", short:"Inspire women to achieve their ambitions. Curious about Mirjam's story? \n" + "\n Get to know her better, follow her on LinkedIn.", image: mirjam, small: mirjamSmall, artwork: mirjamArt, cut: mirjamCut, linkedinUrl: 'https://www.linkedin.com/in/mirjamkramer/' },
    {id: 8, name: 'Joël', content: 'Be Confident. Have Fun. Do Amazing Things. Gives attention to people and entrepreneurship.\n' + '\nCurious about Joël? Follow him.', short:'Be Confident. Have Fun. Do Amazing Things. Gives attention to people and entrepreneurship.\n' + '\nCurious about Joël? Follow him.', image: joel, small: joelSmall, artwork: joelArt, cut: joelCut, linkedinUrl: 'https://www.linkedin.com/in/joeltimmerman/' },

];

function People(props)  {
    let pInterval = null;
    let count = 0
    let [peopleIndex, setPeopleIndex] = useState(0);

    // on person select variables
    let [artWork, setArtWork] = useState(false);
    let [activeId, setActiveId] = useState(null);
    let [clicked, setClicked] = useState(false);
    let [selectedImage, setSelectedImage] = useState('');
    let [linkedIn, setLinkedIn] = useState('');
    let [singleContent, setSingleContent] = useState('');
    let [shortContent, setShortContent] = useState('')
    let [name, setName] = useState('')
    let [popup, setPopup] = useState(false)
    let [content, switchContent] = useState(false);


    useEffect(() => {
        if(props.trigger) {
            setArtWork(true);
            pInterval = setInterval((i) => {
                artLoop()
            }, 5000);
        }
        return () => clearInterval(pInterval);
    }, [props.trigger]);


    const artLoop = () => {

        if (count >= peopleArr.length - 1) {
            count = 0
            setPeopleIndex(count);
            return null;

        }

        setArtWork(false);
        count++

        setTimeout(() => {
            setPeopleIndex(count);
            setArtWork(true);
        }, 1000)

    }

    const personClick = (el, i, id) => {
        el.preventDefault();
        el.stopPropagation();
        switchContent(false);

        // clear artwork interval
        clearInterval(pInterval);

        // On click
        setClicked(true);

        setTimeout(() => {



            setSelectedImage(peopleArr[i].cut);
            selectPerson(i)
            switchContent(true);

        }, 700);

        setActiveId(id);

    };

    const selectPerson = (i) => {

        setLinkedIn(peopleArr[i].linkedinUrl);
        setName(peopleArr[i].name);
        setSingleContent(peopleArr[i].content);
        setShortContent(peopleArr[i].short);
    }

    const contentAnim = {
        initial: {
            opacity: 0,
            y: '40px'
        },
        animate: {
            opacity: 1,
            y: 0
        }
    }

    const smallProfileAnimation = {
        initial: {},
        animate: {
            transition: {
                delayChildren: .3,
                staggerChildren: .1,
                type: 'tween'
            }
        }
    }

    const itemAnimation = {
        initial: {
            opacity: 0,
            y: '100%',
        },
        animate: {
            opacity: 1,
            y: 0
        }
    }

    const artworkAnim = {
        initial: {
            x: 0,
            opacity: 0
        },
        animate: {
            x: 0,
            opacity: 1
        }
    }

    const switchPersonAnim = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        }
    }

    const handlePopUpContent = () => {
        setPopup(!popup)
    }




    return (
            <motion.div variants={function() {
                if (props.prevActiveSection === 7 && props.activeSection === 8) {
                    return {
                        initial: {
                            x: "100%",
                            y: 0,
                            opacity: 1,
                        },
                        animate: {
                            x: 0,
                            y: 0,
                            opacity: 1,
                        },
                    };
                }
                if (props.prevActiveSection === 8 && props.activeSection === 7) {
                    return {
                        initial: {
                            x: 0,
                            y: 0,
                            opacity: 1,
                        },
                        animate: {
                            x: "100%",
                            y: 0,
                            opacity: 1,
                        },
                    };
                }
                if (props.prevActiveSection === 8 && props.activeSection === 9) {
                    return {
                        initial: {
                            x: 0,
                            y: 0,
                            opacity: 1
                        },
                        animate: {
                            x: 0,
                            y: 0,
                            opacity: 1
                        },
                    };
                }
                if (props.prevActiveSection === 9 && props.activeSection === 8) {
                    return {
                        initial: {
                            x: 0,
                            y: 0,
                            opacity: 1
                        },
                        animate: {
                            x: 0,
                            y: 0,
                            opacity: 1
                        },
                    };
                }
                return {
                    initial: {
                        x: "0%",
                        y: 0,
                        opacity: 0,
                    },
                    animate: {
                        x: 0,
                        y: 0,
                        opacity: 1,
                    },
                };
            }()} initial={"initial"} animate={"animate"} transition={{duration: 1, type: 'tween'}} className={`full-height section-9 people ${props.trigger ? 'active-section' : '' }`} id={'people'}>
                {props.trigger ? (
                    <SectionNineCurves trigger={props.trigger} clicked={clicked} />
                ) : ""}

                <div className="people__inner" >
                    <div className="people__content">
                        <motion.h3 variants={contentAnim} initial={'initial'} animate={props.trigger ? 'animate' : ''} transition={{delay: .6,duration: 1 , type: "tween"}}>Our people.</motion.h3>
                        <motion.p variants={contentAnim} initial={'initial'} animate={props.trigger ? 'animate' : ''} transition={{delay: .6,duration: 1 , type: "tween"}}>Driven, enthusiastic and ambitious individuals. Each of them leaders in their field.</motion.p>
                        <motion.div variants={smallProfileAnimation} initial={"initial"} animate={props.trigger ? 'animate' : ''} className="people__select">
                            {peopleArr.map((peep, i) => {

                                return (
                                    <motion.div
                                        variants={itemAnimation}
                                        className={`people__image ${activeId === peep.id && 'active'}`}
                                         key={peep.id}
                                         onClick={(e) => personClick(e, i, peep.id)}
                                         style={{
                                             backgroundImage: `url(${activeId === peep.id ? peep.small : peep.image})`,
                                             backgroundPosition: 'center',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat'
                                         }}>
                                    </motion.div>
                                )
                            })}
                        </motion.div>
                    </div>
                    <div className={`people__art-container ${clicked ? 'flex-bottom' : '' }`}>
                            {!clicked ? (

                                <motion.img variants={artworkAnim} initial={'initial'} animate={props.trigger && artWork ? 'animate' : '' } transition={{duration: .6}}  src={peopleArr[peopleIndex].artwork} alt=""/>

                            )
                                : (
                                    <div className={'people__info'}>
                                        <motion.h4  variants={contentAnim} initial={'initial'} animate={content ? 'animate' : '' } transition={{duration: .2, type: 'tween'}}>{name}</motion.h4>

                                        { function() {
                                            if(window.innerWidth < 768) {
                                                return (
                                                    <motion.p variants={contentAnim} initial={'initial'} animate={content ? 'animate' : '' } transition={{duration: .2, type: 'tween'}}>{shortContent} <span onClick={handlePopUpContent}>Read More</span></motion.p>
                                                )
                                            } else {
                                                return <motion.p variants={contentAnim} initial={'initial'} animate={content ? 'animate' : '' } transition={{duration: .2, type: 'tween'}}>{singleContent} </motion.p>
                                            }

                                        }()}
                                        <LinkedinIcon url={linkedIn} />
                                    </div>
                                )}
                    </div>
                </div>
                <AnimatePresence>
                {popup ? (
                    <motion.div variants={function() {
                        return {
                            initial: {
                                y: "100%",
                                opacity: 0
                            },
                            animate: {
                                y: 0,
                                opacity: 1
                            },exit: {
                                y: "100%",
                                opacity: 0
                            }
                        }
                    }()} initial={"initial"} exit={"exit"} transition={{type: "tween", duration: .5}} animate={"animate"} className="popupContent">
                        <div className={"popup__inner"}>
                            <h4>{name}</h4>
                            <p>{singleContent} </p>
                            <div className="btn-close" onClick={handlePopUpContent}></div>
                        </div>
                    </motion.div>
                ) : "" }
                </AnimatePresence>

                {clicked ? (
                    <AnimatePresence>
                            <motion.div variants={animations.fade} initial={"initial"} animate={"animate"} exit={"exit"} className={"people__background"} style={{ backgroundImage: `url(${background})` }}>
                                    <motion.img variants={switchPersonAnim} key={selectedImage} initial={'initial'} animate={content ? 'animate' : ''} transition={{duration: .2}} src={selectedImage} alt=""/>
                                    <div className="people__gradient">

                                    </div>
                                <svg className={"person-curve"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 749.09 415.18">
                                    <path d="M12.21 400.5L219.71 110s61-95 176-95 187 135 187 135l153.5 257.5" fill="none" stroke="#db151d" stroke-miterlimit="10" stroke-width="30"/>
                                </svg>
                            </motion.div>
                    </AnimatePresence>
                ) : ''}
            </motion.div>
    )
}

export default People