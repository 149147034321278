import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import animations from "../../helpers/animations";


const SectionSixCurves = (props) => {



    return (

        <div className={"curves"}>

            <AnimatePresence>

                {function renderSectionSixArrowTop() {
                    if (props.prevActiveSection === 4 && props.activeSection === 5) {
                        return <motion.svg variants={animations.sectionSixCurveTopA} initial={"initial"} animate={"animate"} transition={{delay: .4, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 5 && props.activeSection === 4) {
                        return <motion.svg variants={animations.sectionSixCurveTopB} initial={"initial"} animate={"animate"} transition={{delay: .4, type: 'tween', duration: 1,ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 5 && props.activeSection === 6) {
                        return <motion.svg variants={animations.sectionSixCurveTopC} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1,ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 6 && props.activeSection === 5) {
                        return <motion.svg variants={animations.sectionSixCurveTopD} initial={"initial"} animate={"animate"} transition={{ delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 6 && props.activeSection === 7) {
                        return <motion.svg variants={animations.sectionSixCurveTopE} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1,ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 7 && props.activeSection === 6) {
                        return <motion.svg variants={animations.sectionSixCurveTopF} initial={"initial"} animate={"animate"} transition={{ delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-top"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,9l89,118.61s40.74,58.05,113.86,51.91c62.1-5.22,91.26-45.77,91.26-45.77L393.76,18.21"/></g></g></motion.svg>
                    }
                    return null;
                }()}
            </AnimatePresence>

            <AnimatePresence>
                {function renderSectionSixArrowBottom() {
                    if (props.prevActiveSection === 4 && props.activeSection === 5) {
                        return <motion.svg variants={animations.sectionSixCurveBottomA} initial={"initial"} animate={"animate"} transition={{delay: .4, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 5 && props.activeSection === 4) {
                        return <motion.svg variants={animations.sectionSixCurveBottomB} initial={"initial"} animate={"animate"} transition={{delay: .4, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 5 && props.activeSection === 6) {
                        return <motion.svg variants={animations.sectionSixCurveBottomC} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 6 && props.activeSection === 5) {
                        return <motion.svg variants={animations.sectionSixCurveBottomD} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 6 && props.activeSection === 7) {
                        return <motion.svg variants={animations.sectionSixCurveBottomE} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    if (props.prevActiveSection === 7 && props.activeSection === 6) {
                        return <motion.svg variants={animations.sectionSixCurveBottomF} initial={"initial"} animate={"animate"} transition={{delay: 0, type: 'tween', duration: 1, ease: 'easeInOut'}} className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.71 194.97"><g id="Laag_2" data-name="Laag 2"><g id="Laag_1-2" data-name="Laag 1"><path  fill={'none'} stroke={"#1a292e"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M12,186,101,67.36s40.74-58,113.86-51.91c62.1,5.22,91.26,45.77,91.26,45.77l87.62,115.54"/></g></g></motion.svg>
                    }
                    return null;
                }()}
            </AnimatePresence>



        </div>
    )
};

export default SectionSixCurves