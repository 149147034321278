import React, {useState, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import SwipeListener from 'swipe-listener';
import Sections from './Sections';
import Menu from "./Components/Menu/Menu";
import {motion} from "framer-motion";
import animations from "./helpers/animations";

import DeviceNotSupported from './Components/DeviceNotSupported';


let sectionChangeTimeout = null;
const Application = () => {
    let [state, setState] = useState({
        activeSection: 0,
        prevActiveSection: null,
    });

    useEffect(() => {
        const body = document.querySelector('body');
        let listener = SwipeListener(body);
        body.addEventListener('wheel', handleScrollOrSwipe);
        body.addEventListener('swipe', handleScrollOrSwipe);
        return () => {
            body.removeEventListener('wheel', handleScrollOrSwipe);
            body.removeEventListener('swipe', handleScrollOrSwipe);
        };
    });

    const handleScrollOrSwipe = (ev) => {
        let directions = undefined;
        if (ev.detail && ev.detail.directions) {
            directions = ev.detail.directions;
        } else {
            directions = {
                bottom: ev.deltaY < 0,
                top: ev.deltaY > 0,
            };
        }
        if (sectionChangeTimeout) return null;
        
        let changedSection = false;
        if (directions.bottom) { // up
            changedSection = previousSection();
        } else if (directions.top) { // down
            changedSection = nextSection();
        }

        if (changedSection) {
            sectionChangeTimeout = setTimeout(() => {
                sectionChangeTimeout = null;
            }, 2000);
        }

        //ev.preventDefault();
        ev.stopPropagation();
    };

    const previousSection = () => {
        if (state.activeSection > 0) {
            setState({
                prevActiveSection: state.activeSection,
                activeSection: state.activeSection - 1,
            });
            return true;
        }
        return false;
    }

    const nextSection = () => {
        if (state.activeSection < 11) {
            setState({
                prevActiveSection: state.activeSection,
                activeSection: state.activeSection + 1,
            });
            return true;
        }
        return false;
    }

    const setActiveSectionFunction = (num) => {
        setState({
            prevActiveSection: state.activeSection,
            activeSection: num,
        });
    }
    
    const goHome = () => {
        setActiveSectionFunction(0);
    };

    return (
        <div className={'elevens'}>
            <DeviceNotSupported />
            <div className={`${(state.prevActiveSection === 6 && state.activeSection === 7)  ? 'delay' : ''} ${(state.prevActiveSection === 7 && state.activeSection === 6)  ? 'no-transition' : ''} elevens__background ${(state.activeSection === 0 || state.activeSection === 2 || state.activeSection === 8 || state.activeSection === 9 ) ? 'green' : ''} ${(state.activeSection === 1 ) ? 'blue' : ''} ${(state.activeSection === 6 || state.activeSection === 4 || state.activeSection === 3) ? 'lightblue' : ''} ${(state.activeSection === 5 || state.activeSection === 10 || state.activeSection === 11) ? 'white' : ''}${state.activeSection === 7 ? 'red' : ''}`}></div>
            <div className="fixed-logo">

                <svg onClick={goHome} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.1 142.6">
                    <path className={'logo-i'} d="M218.2 138.6h-42c-1.1 0-2-.9-2-2V5.9c0-1.1.9-2 2-2h42c1.1 0 2 .9 2 2v130.7c0 1.1-.9 2-2 2zm-40-4h38V7.9h-38v126.7z" fill={(state.activeSection === 0 || state.activeSection === 2 || state.activeSection === 7 || state.activeSection === 8 ) ? '#fff' : "#1A2A2E"}/>
                    <path className={'logo-i'} d="M151.1 138.6h-48.5c-.6 0-1.2-.3-1.6-.8l-15.7-21.2 2.5-3.3 15.8 21.3h43.5l-31.2-42.1c-3.2-4.2-8-6.7-13.3-6.7-5.3 0-10.1 2.4-13.3 6.7l-33.6 45.3c-.4.5-1 .8-1.6.8H5.5c-.8 0-1.4-.4-1.8-1.1-.3-.7-.3-1.5.2-2.1l47.6-64.2L3.9 7.1c-.4-.6-.5-1.4-.1-2.1s1-1.1 1.8-1.1h48.5c.6 0 1.2.3 1.6.8L89.3 50c3.2 4.2 8 6.7 13.3 6.7 5.3 0 10.1-2.4 13.3-6.7l31.2-42.1h-43.5L87.8 29.2l-2.5-3.3L101 4.7c.4-.5 1-.8 1.6-.8h48.5c.8 0 1.4.4 1.8 1.1.3.7.3 1.5-.2 2.1l-33.6 45.3c-3.9 5.3-9.9 8.3-16.5 8.3s-12.6-3-16.5-8.3l-33-44.5H9.5l46.1 62.2c.5.7.5 1.7 0 2.4L9.5 134.6H53l33-44.5c3.9-5.3 9.9-8.3 16.5-8.3s12.6 3 16.5 8.3l33.6 45.3c.5.6.5 1.4.2 2.1-.3.7-1 1.1-1.7 1.1z" fill={(state.activeSection === 6 || state.activeSection === 7) ? '#fff' : "#db171e"}/>
                </svg>
            </div>

            <div className="scroller">
                <motion.div variants={animations.bottomSmallTextAnimation} initial={'hidden'} animate={(state.activeSection === 0 || state.activeSection === 1 || state.activeSection === 2 ) ? 'visible'  : ''  } className="scroller__inner">

                </motion.div>
                <motion.span variants={animations.bottomSmallTextAnimation} initial={'hidden'} animate={(state.activeSection === 0 || state.activeSection === 1 || state.activeSection === 2 ) ? 'visible'  : ''  }>
                    scroll
                </motion.span>
            </div>

            <Menu setActiveFunction={setActiveSectionFunction} currentSectionIndex={state.activeSection} />
            <Sections goHome={goHome} setActiveFunction={setActiveSectionFunction}  activeSection={state.activeSection} prevActiveSection={state.prevActiveSection} />
        </div>
    )
}


ReactDOM.render(
          <React.StrictMode>
             <Application/>
          </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();