import React from 'react';
import {motion} from 'framer-motion';
import animations from "../../helpers/animations";


const SectionOneCurves = (props) => {

    const slideIn = {
        initial: {
            x: "-110%",
            y: "-50%",
            originX: "center"
        },
        animate: {
            x: "0",
            y: "-50%",
        }
    }

  return (

      <div className={"curves"}>
          <motion.svg variants={slideIn} initial={"initial"} animate={props.trigger ? "animate" : ''}  transition={{ duration: 1.2, type: 'tween' }} className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.06 432.36">
              <motion.path d="M16.33,11.15,166.54,212.87a4.41,4.41,0,0,1,0,5.27L13.43,425.65" transform="translate(-1.36 -2.2)" fill={"none"} stroke={"#db151d"} strokeMiterlimit={"10"} strokeWidth={"30px"}/>
          </motion.svg>

          <svg className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330.37 138.17">
              <g id="Laag_2" data-name="Laag 2">
                  <g id="Laag_1-2" data-name="Laag 1">
                      <motion.path variants={animations.fade} initial={"initial"} animate={props.trigger ? "animate" : ''} transition={{ duration: .8 }} fill={'none'} stroke={"#db151d"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M13.71,131.84S65.61,15,169.43,15c92.93,0,147.35,116.84,147.35,116.84"/>
                  </g>
              </g>
          </svg>

          <svg className={"curve-top"}  data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 314.91 130.15">

              <motion.path variants={animations.fade} initial={"initial"} animate={props.trigger ? "animate" : ''}  transition={{ duration: .8  }} fill={'none'} stroke={"#db151d"} strokeMiterlimit={"10"} strokeWidth={"30px"}  d="M15.63,14.45S65,119.92,159.63,119.93C264.28,120,302.79,9.73,302.79,9.73"
                    transform="translate(-2.05 -4.79)"/>
          </svg>
      </div>
  )
};

export default SectionOneCurves