import React from 'react';
import {motion} from 'framer-motion';
import animations from "../../helpers/animations";


const SectionNineCurves = (props) => {


    return (

        <div className={"curves"}>
            <svg className={'arrow'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.06 543.49">
                <g id="Laag_2" data-name="Laag 2">
                    <g id="Laag_1-2" data-name="Laag 1">
                        <path fill={'none'} stroke={"#db121c"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M15.34,11.31l188.07,253.8a8.4,8.4,0,0,1,0,10L15.34,532.28"/>
                    </g>
                </g>
            </svg>

            <svg className={"curve-top"}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.26 155.19">
                <g id="Laag_2" data-name="Laag 2">
                    <g id="Laag_1-2" data-name="Laag 1">
                        <path fill={'none'} stroke={"#db121c"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M17.05,8.38S77.88,132.09,158.29,136.22"/>
                    </g>
                </g>
            </svg>


            <svg className={"curve-bottom"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.26 155.19">
                <g id="Laag_2" data-name="Laag 2">
                    <g id="Laag_1-2" data-name="Laag 1">
                        <path fill={'none'} stroke={"#db121c"} strokeMiterlimit={"10"} strokeWidth={"30px"} className="cls-1" d="M17.05,146.81S77.88,23.1,158.29,19"/>
                    </g>
                </g>
            </svg>


        </div>
    )
};

export default SectionNineCurves